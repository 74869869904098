body {
    /* Typography */
    font-family: 'Neue Haas Unica', 'Noto Sans', sans-serif;
}
h1 {
    /* Typography */
    font-weight: 400;
}

/* INPUTS */
.input-label-combo {
    /* Variables */
    --input-height:             48px;
    --input-vertical-padding:   14px;
    --input-horizontal-padding: 16px;
    --input-vertical-margin:    16px;
    --font-size:                16px;
    --line-height:              20px;

    /* Positioning */
    position: relative; /* Creating positioning context */

    /* Box Model */
    margin: var(--input-vertical-margin) 0 0;

    /* Typography */
    font-size: 0;

    &:first-child {
        /* Box Model */
        margin-top: 0;
    }

}

input, textarea {
    /* Variables */
    $input-dimensions--padding-vertical:   var(--input-vertical-padding);
    $input-dimensions--padding-horizontal: var(--input-horizontal-padding);
    $input-typography--font-size:          var(--font-size);
    $input-typography--line-height:        var(--line-height);

    /* Box Model */
    box-sizing: border-box;
    width:      100%;
    padding:    $input-dimensions--padding-vertical $input-dimensions--padding-horizontal;

    /* Typography */
    font-size:     $input-typography--font-size;
    line-height:   $input-typography--line-height;
    white-space:   nowrap;
    text-overflow: ellipsis;

    /* Other */
    background-color: white;
    border:           none;
    border-radius:    4px;
    box-shadow:       0 0 0 1px #BDBDBD;
    outline:          none;
}

label {

    &.is--placeholder {
        /* Other */
        cursor: text;

        .input-label-combo & {
            // NOTE: <label.is--placeholder> should always be inside form-combos

            /* Variables */
            $label-position--top:           var(--input-vertical-padding);
            $label-position--left:          var(--input-horizontal-padding);
            $label-typography--color:       #757575;
            $label-typography--font-size:   var(--font-size);
            $label-typography--line-height: var(--line-height);      ;

            /* Typography */
            color:         $label-typography--color;
            font-size:     $label-typography--font-size;
            line-height:   $label-typography--line-height;
            white-space:   nowrap;
            text-overflow: ellipsis;

            /* Positioning */
            position: absolute;
            top:  $label-position--top;
            left: $label-position--left;

            /* Other */
            pointer-events: none;
        }

        .input-label-combo textarea:focus + &,
        .input-label-combo textarea.tiene-valor + &,
        .input-label-combo input:focus + &,
        .input-label-combo input.tiene-valor + & {
            /* Variables */
            $label-typography--font-size:   12px;
            $label-typography--line-height: 16px;
            $label-background--mid-point:   calc( ( #{$label-typography--line-height} / 2 ) + 1px ); // Accounts for 1px box-shadow

            /* Box Model */
            padding: 0 3px;

            /* Positioning */
            top: calc( ( #{$label-typography--line-height} / 2 ) * -1 );

            /* Typography */
            font-size:   $label-typography--font-size;
            line-height: $label-typography--line-height;

            /* Other */
            background-image: linear-gradient(to top,
                                              white,
                                              white #{$label-background--mid-point},
                                              rgba(0,0,0,0) #{$label-background--mid-point},
                                              rgba(0,0,0,0) 16px);
        }
    }
}

.visibility-control {
    /* Variables */
    $icon-dimensions--size: 24px;
    $icon-position--top:    calc( ( var(--input-height) - #{$icon-dimensions--size} ) / 2 );
    $icon-position--right:  calc( var(--input-horizontal-padding) / 2 );

    content: '';

    /* Box Model */
    height: $icon-dimensions--size;
    width:  $icon-dimensions--size;

    /* Positioning */
    position: absolute;
    top:      $icon-position--top;
    right:    $icon-position--right;

    /* Other */
    $image-eye-url: $image-path + '/icon--eye.svg';
    background-image:    url($image-eye-url);
    background-color:    transparent;
    background-position: center;
    background-repeat:   no-repeat;
    background-size:     contain;
    border:              none;
    outline:             none;
}

%feedback {
    /* Variables */
    $feedback-position--left:          var(--input-horizontal-padding);
    $feedback-typography--font-size:   12px;
    $feedback-typography--line-height: 16px;

    /* Box Model */
    padding: 0;
    margin:  calc( #{$feedback-typography--line-height} / 4) 0 0;

    /* Typography */
    font-size:   $feedback-typography--font-size;
    line-height: $feedback-typography--line-height;

    .input-label-combo & {
        /* Positioning */
        position: relative;
        left:     $feedback-position--left;
    }
}

.feedback--positive,
.feedback--warning {
    @extend %feedback;

    &::before {
        /* Variables */
        $icon-dimensions--size: 16px;

        content: '';

        /* Box Model */
        display:      inline-block;
        height:       $icon-dimensions--size;
        width:        $icon-dimensions--size;
        margin-right: calc( #{$icon-dimensions--size} / 2.6 );

        /* Positioning */
        position: relative;
        top:      calc( #{$icon-dimensions--size} / 4 );

        /* Other */
        $image-warning-url: $image-path + '/icon--info-warning.svg';
        background-image:    url($image-warning-url);
        background-position: center;
        background-repeat:   no-repeat;
        background-size:     contain;
    }
}

.feedback--positive {
    /* Typography */
    color: #00973C;

    &::before {
        /* Other */
        $image-check-url: $image-path + '/icon--check-circle.svg';
        background-image:    url($image-check-url);
    }
}

.feedback--warning {
    /* Typography */
    color: #FF6042;

    &::before {
        /* Other */
        $image-warning-url: $image-path + '/icon--info-warning.svg';
        background-image:    url($image-warning-url);
    }
}

