.reservations {

    .desktop & {
    }

    &.alert {
        background-color: $background-color--attention;
        color: $color--inverted;
    }
}
.reservations__title {
    /* Box Model */
    padding: 0;
    margin:  0 0 16px;

    /* Typography */
    @extend %font-style--title;
    font-size:   20px;
    font-weight:  400;
    line-height: 20px;
    text-transform: uppercase;
}
.reservations__text {
    /* Box Model */
    display: block;
    padding: 0;
    margin:  0 0 16px;

    /* Typography */
    font-size:   16px;
    font-weight:  400;
    line-height: 25px;
}
.reservations__controls-list {
    /* Box Model */
    margin:  0;
    padding: 0;

    /* Other */
    list-style-type: none;
}
.reservations__control {
    /* Box Model */
    margin:   0 0 12px;

    &:last-child {
        /* Box Model */
        margin: 0;
    }
}
button[class*="button--reservation-"] {
    @extend %button--no-background;

    /* Positioning */
    position: relative; /* Adding a positioning context for the chevron icon */

    /* Box Model */
    width:   100%;
    height:  60px;
    padding: 14px 16px;
    margin:          0;

    /* Typography */
    // color: white;
    color: $color--inverted;
    text-align: left;

    /* Other */
    background-color: $background-color--attention;
    border-radius: 2px;

    .text {
       /* Typography */
       font-size:   14px;
       font-weight:  400;
       line-height: 19px;
    }
    .text--highlight {
        /* Typography */
        @extend %font-style--title;
        font-size:   18px;
        font-weight:  700;
        line-height: 19px;

        &::after {
            content: ' - ';
        }
    }
    *[class*="icon--"] {
        /* Positioning */
        position: absolute;
        top:   0;
        right: 0;

        /* Flexbox */
        display: flex;
        align-items: center;

        /* Box Model */
        box-sizing: border-box;
        height: 100%;
        //padding: 8px;
        padding: 20px;
        transform: rotate(180deg);

        /* Font Awesome */
        font-size: 12px;
        font-weight: 400;
    }

    .alert & {
        background-color: $background-color--base;
        color: $color--primary;
    }

    &:hover {
        background-color: white;
        color: $color--attention;
        border-color: $color--attention;
        border-style: solid;
        border-width: 1px;
        path {
            stroke: $color--attention;
        }
    }
}