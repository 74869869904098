.my-week {
    /* Box Model */
    margin-top:  16px;
    padding:   0 16px;

    h1 {
        /* Box Model */
        margin: 0;

        /* Typograhpy */
        @extend %font-style--title;
        font-size: 18px;
        line-height: 22px;
        text-transform: uppercase;
    }

    .desktop & {
        /* Box Model */
        margin-top:  24px;
        padding:   0 38px;

        h1 {
            /* Typography */
            // font-family: 'Rubik', sans-serif;
            font-size:   21px;
            font-weight:  400;
            line-height: 25px;
        }
    } // .desktop

    .my-events & {
        .desktop & {
            /* Box Model */
            padding: 0;
        }
    } // .my-events
}
.event-list {
    /* Box Model */
    padding: 0;
    margin:  0;

    /* Other */
    list-style-type: none;
}
.event-item {
    /* Flexbox */
    display: flex;
    flex-direction: row;

    /* Box Model */
    //height: 60px;
    height: 65px;
    width:  100%;
    margin-top: 11px;
    border-radius: 2px;

    /* Other */
    background-color: white;
    box-shadow: 0 0 0 1px #F0F0F0;

    .mobile .historial-page & {
        height: 85px;

    }

    .desktop & {
        /* Box Model */
        margin-top: 16px;

        &:hover {
            box-shadow: 0 0 0 1px $color--attention;
        }

    }
}
.event-item__date {
    /* Flexbox Child */
    flex: 0 0 50px;

    /* Flexbox */
    display: flex;
    flex-direction: column;
}
.event-item__day {
    /* Box Model */
    height: 25px;

    /* Typography */
    color: white;
    text-align: center;
    text-transform: uppercase;

    /* Other */
    background-color: $background-color--attention;
    border-radius: 2px 0 0 0;

    .desktop .historial-page & {
        display: none;
    }
}
.event-item__fullday {
    /* Box Model */
    height: 25px;
    width: 120px;

    /* Typography */
    color: $color--inverted;
    text-align: center;
    text-transform: uppercase;

    /* Other */
    background-color: $background-color--attention;
    border-radius: 2px 0 0 0;

    .mobile & {
        display: none;
    }
}
.event-item__date-number {
    /* Flexbox */
    display: flex;
    align-items:     center;
    justify-content: center;

    /* Box Model */
    height: 100%;

    /* Typography */
    @extend %font-style--title;
    color: $color--attention;
    font-size:   20px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 0.6px;
    text-align: center;

    /* Other */
    background-color: rgba(246,105,29,0.1);
    border-radius: 0 0 0 2px;
}
.event-item__fecha {
    /* Flexbox */
    display: flex;
    align-items:     center;
    justify-content: center;

    /* Box Model */
    height: 100%;

    /* Typography */
    @extend %font-style--title;
    color: $color--attention;
    font-size:   12px;
    line-height: 14px;
    font-weight: 400;
    letter-spacing: 0.6px;
    text-align: center;

    /* Other */
    background-color: rgba(246,105,29,0.1);
    border-radius: 0 0 0 2px;
}
.event-item__details {
    /* Flexbox Child */
    flex: 4.5;

    /* Flexbox */
    display: flex;
    flex-direction: column;

    /* Box Model */
    padding: 11px;

}
.event-item__type {
    /* Typography */
    color: #1A1E21;
    @extend %font-style--title;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
}
.wrapper--second-row {
    /* Flexbox */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    /* Box Model */
    margin-top: 4px;
}
.wrapper--third-row {
    /* Flexbox */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    /* Box Model */
    margin-top: 4px;

    .desktop & {
        /* Inline with second row */
        position: relative;
        top: -1.45rem;
        left: 50%;
        width: 50%;
    }
}
.event-item__time,
.event-item__place {
    /* Typography */
    color: #666;
    font-size:   12px;
    line-height: 16px;

    i {
        margin-right: 4px;
    }
}
.event-item__time {
    /* Flexbox Child */
    flex: 0 0 65px;

    /* Box Model */
    margin-right: 11px;

    .icon--clock {
        margin-top: -1px;
        margin-right: 2px;
    }
}
.event-item__place {
    /* Flexbox Child */
    flex: 1 1 auto;
//    max-width: 60%;

    /* Box Model */
    overflow: hidden;

    /* Typography */
    text-overflow: ellipsis;
//    white-space: nowrap;

    .icon--map-pin {
        margin-top: -7px;
        margin-right: 2px;
    }

    .event-item__place--text {
        display: inline-block;
        width: 105px;

        .desktop & {
            width: auto;
        }

        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}
.event-item__controls {
    /* Flexbox */
    flex: 0 0 30px;
}
.event-item__more-information {
    @extend %button--no-background;

    /* Box Model */
    height: 100%;
    width:  100%;

    /* Font Awesome */
    font-size: 8px;

    /* Other */
}

// DEBUG DEBUG ---- Event Types ---- DEBUG DEBUG
.event-type {

    &--purple {
        .event-item__day {
            /* Other */
            background-color: #8000A0;
        }
        .event-item__fullday {
            /* Other */
            background-color: #8000A0;
        }
        .event-item__date-number {
            /* Typography */
            color: #8000A0;

            /* Other */
            background-color: rgba(128,0,160,0.1);
        }
        .event-item__fecha {
            /* Typography */
            color: #8000A0;

            /* Other */
            background-color: rgba(128,0,160,0.1);
        }
    }
}