$color--primary: black;
$color--secondary: #919eab;
$color--tertiary: #212B36;
$color--inverted: white;
$color--attention: #ffac8f;
// $color--attention: #eb9569;
$color--success: #1D8663;
$color--warning: #D25241;
$background-color--primary:  rgba(196, 196, 196, 1);
$background-color--secondary: white;
$background-color--inverted: black;
$background-color--tertiary: #666666;
$background-color--attention: #ffac8f;
// $background-color--attention: #eb9569;
$background-color--disabled: #EBEBEB;
$background-color--base: #FAFAFA;
$background-color--exito: #059E5A;

// Font styles
%font-style--attention {
  color: $color--attention;
  font-size: 12px;
  line-height: 18px;
  font-weight: 900;
  text-transform: uppercase;
}

%font-style--title {
  font-family: Adieu;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
}
