.navigation-list {
    /* Flexbox */
    display: flex;
    flex-direction: row;
    align-items: center;

    /* Box Model */
    margin:  0;
    padding: 0;

    /* Other */
    list-style-type: none;

    .desktop & {
        /* Flexbox */
        flex-direction: column;
    }
}
.navigation-item {
    /* Flexbox-child */
    flex: 1;

    /* Flexbox */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    /* Box Model */
    height: 100%;

    .desktop & {
        /* Flexbox-child */
        flex: 0 0 68px;

        /* Box Model */
        height: 68px;
        width:  100%;


        &:hover {
            //background-color: $background-color--attention;
            //color: $color--inverted;
            /*
            &:after {
                position: absolute;
                content: "Text";
                background-color: black;
                color: white;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 10px;
                left: 82px;
                height: 60px;
                width: 100px;
            }
            */
        }

    }
}
.navigation-item-link {
    @extend %button--no-background;
    @extend %button--icon-and-text--column;

    .icon-- {
        background-position: center;
        background-repeat: no-repeat;
        background-clip: content-box;
        background-size: cover;
    }

    .home {
        background-image: url('/img/icon--home-black.svg');
        .is-active & {
            background-image: url('/img/icon--home-orange.svg');
        }
    }

    .experiencias {
        background-image: url('/img/icon--experiencias-black.svg');
        .is-active & {
            background-image: url('/img/icon--experiencias-orange.svg');
        }
    }

    .reservas {
        background-image: url('/img/icon--reservas-black.svg');
        .is-active & {
            background-image: url('/img/icon--reservas-orange.svg');
        }
    }

    .comunidad {
        background-image: url('/img/icon--comunidad-black.svg');
        .is-active & {
            background-image: url('/img/icon--comunidad-orange.svg');
        }
    }

    .ayuda {
        background-image: url('/img/icon--ayuda-black.svg');
        .is-active & {
            background-image: url('/img/icon--ayuda-orange.svg');
        }
    }

    /* Flexbox */
    justify-content: center;

    /* Box Model */
    height: 100%;
    width:  100%;
    padding:   0;

    /* Typography */
    font-size: 10px;

    /* Icons */
    *[class^="icon"] {
        /* Font Awesome */
        font-size: 16px;
        width: 16px;
        height: 16px;

        .desktop & {
            /* Font Awesome */
            font-size: 24px;

            width: 24px;
            height: 24px;
        }
    }

    span {
        /* Typography */
        //font-family: 'Rubik', sans-serif;

        .desktop & {
            /* Box Model */
            display: none;
        }
    }

    * {
        /* Box Model */
        margin: 2px 0;
    }

    &:focus {
        /**
        * DEBUG DEBUG
        *
        * It is important to reintroduce some :focus styles to help with
        * tab-navigation.
        *
        * DEBUG DEBUG
        */
        outline: none;
    }

    .navigation-item.is-active & {
        * {
            /* Typography */
            color: $color--attention;
        }
        span {
            /* Typography */
            font-weight: 500;
        }

        .desktop & {
            /* Other */
            background-color: #F4F6F8;
            box-shadow: inset 3px 0 0 0 $background-color--attention;
        }
    }
}