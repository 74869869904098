/* Variables */
//$experience-width:  156px;
$experience-width:  44vw;
$experience-image-height: 84px;
$experience-image-height-desktop: 150px;
$main-spacing:       16px;
$atendee-height:     40px;


.experiences-list {
    /* Flexbox */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .desktop & {
        justify-content: flex-start;
        margin-right: -38px;
    }

    /* Box Model */
    margin:  0;
    padding: 0;

    /* Other */
    list-style-type: none;
}
.experience {
    /* Variables */
    $spacing:    $main-spacing;
    $width:  $experience-width;

    /* Positioning */
    position: relative; /* Add positioning context for elements */

    /* Flexbox Child */
    flex: 0 0 $width;

    /* Box Model */
    width: $width;
    margin-top: $main-spacing;
    overflow: hidden;

    .desktop & {
        /* Flexbox */
        display: flex;
        flex-direction: column;

        /* Box Model */
        margin-top:  24px;
        margin-right: 24px;

        /* Other */
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.05);

        &:first-child {
            /* Box Model */
            margin-left: 0;
        }

        &:last-child {
            /* Box Model */
            margin-right: 0;
        }
    }

    &.is-highlighted {
        /* Variables */
        $width: 100%;

        /* Box Model */
        width: $width;

        /* Flexbox */
        flex: 0 0 $width;

        .desktop & {
            /* Flexbox */
            display: flex;
            flex-direction: row;

            /* Box Model */
            height: 255px;
            margin-top: 0;
        }
    }
}
.wrapper--experience__image {
    /* Box Model */
    width: $experience-width;
    height: $experience-image-height;
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;

    /* Other */
    border-radius: 2px;

    .desktop & {
        /* Box Model */
        width:   100%;
        height: $experience-image-height-desktop;

        /* Other */
        border-radius: 2px 2px 0 0;
    }
    .experience.is-highlighted & {
        /* Box Model */
        width: 100%;
        height: $experience-image-height-desktop;

        /* Other */
        border-radius: 2px 2px 0 0;

        .desktop & {
            /**
            * Space Distribution
            * ------------------
            * We want this item to take half the width of .experience.
            * In order to do this, we:
            *
            * 1) Set flex-grow: 1;
            * 2) Set flex-shrink: 1;
            * 3) Set flex-basis: 50%;
            * 4) Set box-sizing: border-box; ... to make sure the width includes padding & borders
            *
            */

            /* Flexbox Child */
            flex: 1 1 50%;

            /* Box Model */
            box-sizing: border-box;
            height: 100%;

            /* Other */
            border-radius: 2px 0 0 2px; /* Set the radius on the top-left & bottom-left corners */
        }
    }
}
.experience__image {
    /* Variables */
    //$width: $experience-width;

    /* --- Imagen con tamaño minimo (no sirve para mega-imagenes) */
    //min-width: 100%;
    //max-width: max-content;
    //min-height: 100%;
    height: auto;
    width: auto;
    width: 100%;
    //max-height: max-content;
    /* */

    /* Box Model */
    //max-height: $width * 0.55;
    //width: 100%;
    //min-width: 100%;

    .desktop & {
        /* Box Model */
        //max-height: fit-content;
        //min-height: 100%;
        //min-width:  100%;
    }
    .experience.is-highlighted & {
        /* Variables */
        //$width: 100%;

        /* Box Model */
        //max-height: $width * 0.46;

        .desktop & {
            /* Box Model */
            //max-height: 100%; /* Override the mobile max-height */
            //min-height: 100%; /* Cover the element's height */
            //min-width: 100%; /* Cover the element's width */
            height: 100%;
            width: auto;
        }

        .mobile & {
            width: 100%;
        }
    }
}
.experience__details {
    /* Box Model */
    padding: 12px 0 0;


    .desktop & {
        /* Flexbox Child */
        flex: 1;

        /* Box Model */
        padding: 16px;

        /* Other */
        background-color: white;
    }

    .experience.is-highlighted & {
        /* Box Model */
        padding: $main-spacing;

        /* Other */
        background-color: white;
        border-radius: 0 0 2px 2px;

        .desktop & {
            /**
            * Space Distribution
            * ------------------
            * We want this item to take half the width of .experience.
            * In order to do this, we:
            *
            * 1) Set flex-grow: 1;
            * 2) Set flex-shrink: 1;
            * 3) Set flex-basis: 50%;
            * 4) Set box-sizing: border-box; ... to make sure the width includes padding & borders
            *
            */

            /* Flexbox Child */
            flex: 1 1 50%;

            /* Box Model */
            box-sizing: border-box;
            // height: 100%;
            padding: ( $main-spacing * 1.5 );

            /* Other */
            border-radius: 0 2px 2px 2px; /* Set the radius on the top-right & bottom-right corners */
        }
    }
}
.experience__date-and-time {
    /* Typography */
    color:  $color--attention;
    font-size:   14px;
    line-height: 17px;

    .desktop & {
        /* Typography */
        font-size:   14px;
        line-height: 17px;
    }

    .experience.is-highlighted & {
        .desktop & {
            /* Typography */
            font-size:   16px;
            line-height: 19px;
        }
    }
}
.experience__title {
    /* Box Model */
    margin: 8px 0 0;

    /* Typography */
    @extend %font-style--title;
    font-size:   14px;
    line-height: 17px;

    .desktop & {
        /* Typography */
        font-size:   18px;
        font-weight:  400;
        line-height: 22px;
    }

    .experience.is-highlighted & {
        /* Typography */
        font-size:   18px;
        line-height: 22px;

        .desktop & {
            /* Typography */
            font-size:   22px;
            font-weight:  500;
            line-height: 26px;
        }
    }
}
.experience__date {
    .mobile & {
        display: block;
    }
}
.experience__location {
    /* Box Model */
    margin: 8px 0 0;

    /* Typography */
    color:     #666;
    font-size:   12px;
    line-height: 14px;

    .experience.is-highlighted & {
        /* Box Model */
        margin: 14px 0 0;
    }

    i {
        /* Box Model */
        margin-right: 4px;
    }

    .icon--map-pin {
        margin-top: -1px;
        margin-right: 2px;
    }

}
.experience__controls {}
.experience__like-button {
    @extend %button--no-background;
    @extend %button--icon-only;

    /* Variables */
    $color: rgba(255,255,255,0.9);
    $shadow:      rgba(0,0,0,0.6);
    $spacing: 12px;

    /* Positioning */
    position: absolute;
    top:   ( $spacing / 2 );
    right: ( $spacing / 2 );

    /* Font Awesome */
    color: $color;
    text-shadow: 0 0 5px $shadow;
    font-size: 18px;

    /* Other */
    opacity: 1;

    .experience.is-highlighted & {
        /* Positioning */
        top:   $spacing;
        right: $spacing;
    }
}
.experience__atendees {
    /* Positioning */
    position: absolute;
    bottom: 24px;
}
.experience__atendees-title {
    /* Box Model */
    margin:  0;
    padding: 0;

    /* Typography */
    color:     #666;
    font-size:   12px;
    font-weight:  400;
    line-height: 14px;
}
.experience__atendees-list {
    /**
    * DEBUG DEBUG
    * -----------
    * [1] : 'Magic number' to prevent this from pushing the whole container
    *       past its allotted width.
    *
    */

    /* Flexbox */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; /* [1] */
    justify-content: flex-start;

    /* Box Model */
    margin:  9px 0 0;
    padding: 0;

    /* Other */
    list-style-type: none;
}
.experience__atendee {
    /* Box Model */
    margin-left: ( ( $atendee-height / 4 ) * -1 );

    &:first-child {
       /* Box Model */
        margin-left: 0;
    }
}
.experience__atendees--counter {
    /* Flexbox */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    /* Box Model */
    height: $atendee-height;
    margin-left: ( ( $atendee-height / 4 ) * -1 );
}
.experience__atendee-avatar {
    /* Box Model */
    height: $atendee-height;
    width:  $atendee-height;
}
.wrapper--atendees-counter {
    /* Variable */
    $dimensions: 28px;

    /* Positioning */
    position: relative;

    /* Flexbox */
    display: flex;
    flex-direction:  column;
    align-items:     center;
    justify-content: center;

    /* Box Model */
    height: $dimensions;
    width:  $dimensions;

    /* Other */
    background-color: black;
    border-radius: 50%;
}
.experience__atendees-amount {
    /* Typography */
    color: white;
    font-size:   12px;
    font-weight:  600;
    line-height: 18px;
}