.menu-list {
  position: relative;
  margin: 0;
  margin-top: 16px;
  list-style-type: none;
  padding: 0;
}

.menu-list-item {
  position: relative;
  height: 60px;
  padding: 0 16px;

  &:hover {
    background: #E7E7E7;
  }
}

.menu-list-item__creditos {
  position: absolute;
  right: 15px;

  @extend %font-style--title;
  font-size: 20px;
  line-height: 20px;

}

.menu-list-item__button {
  @extend %button--no-background;
  @extend %button--icon-only;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  height: 100%;
  width: 100%;

  box-shadow: 0 1px rgba(0,0,0,0.1);

  outline: none;
  &:focus {
    outline: none;
  }

  .menu-list-item:last-child & {
    box-shadow: none;
  }

}

.menu-list-item__icon {
  position: relative;
  width: 32px;
  height: 32px;
  background: rgba(196, 196, 196, 1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  display: none; // Quitado el Icono hasta tener los nuevos
  img {
    position: relative;
    left: -1px;
  }
}

.menu-list-item__title {
  padding: 0 16px;

  font-size: 14px;
  text-align: left;
}

.menu-list__version {
  position: relative;
  display: block;
  text-align: center;
  padding: 0 16px;
  width: 100%;
  font-size: 12px;
}