.primary-navigation {
    /* Variables */
    $background-color: white;
    $box-shadow-color: rgba($color: #000, $alpha: 0.3);

    /* Other */
    background-color: $background-color;
    box-shadow: 0 -1px 5px 0 $box-shadow-color;

    .navigation-list {
        /**
        * We make sure the navigation list fills the height of this container
        */
        height: 100%;
    }
    .wrapper--mqco-logo {
        /* Flexbox */

        
        /**
        * Box Model
        * ---------
        * We hide the logo on all mobile devices
        *
        */
        display: none;
    }

    .desktop & {
        /* Positioning */
        position: fixed;
        top:  0;
        left: 0;

        /* Box Model */
        height: 100vh;
        width:   82px;

        .wrapper--mqco-logo {
            /**
            * Flexbox
            * -------
            * We show the logo on all desktop devices
            *
            */
            display: flex;
            align-items:     center;
            justify-content: center;
            
            /* Box Model */
            width:  100%;
            height: 68px; /* Same height as .navigation-item */
        }
        .mqco-logo {
           /* Box Model */
           width:  60px; 
        }
    }
}