.calendario {
  /* Box Model */
  width: 329px;
  background-color: #ffffff;
  padding-top: 1px;
  box-shadow: inset 0 0 0 1px #f0f0f0;
}

.calendario__row {
  margin-bottom: 16px;
}

.calendario-titulo {
  /* Flex box */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  padding: 0 8px;

}

.calendario__control {
  @extend %button--no-background;
  @extend %button--icon-only;

  &:focus {
    outline: none;
  }

  img {
    width: 6px; // Pedido por Nico 2021-03-17
  }

  /* Flex box */
  flex: 1;
}

.calendario__fecha {
  /* Flex box */
  flex: 8;

  /* Typography */
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;

}

.calendario__row {
  .calendario__nombres, .calendario__dias {
    /* Box Model */
    margin: 0;
    padding: 0;

    /* Flex box */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    /* Other */
    list-style-type: none;

    li {
      /* Flex box */
      flex: 1;

      /* Typography */
      text-align: center;
    }
  }

  .calendario__dia {
    /* Typography */
    color: #333333;
    font-size: 14px;
    line-height: 1.5;

    /* Flex box */
    display: flex;
    justify-content: center;
    align-items: center;

    /* Other */
    cursor: pointer;

    &.is-selected {
      span {
        color: white;
        background-color: $background-color--attention;
        box-shadow: inset 0 0 0 1px $background-color--attention;
        font-weight: bold;
      }
      &.is-no-disponible {
        span {
          background: none;
          box-shadow: none;
        }

      }
    }

    &.is-no-disponible {
      span {
        //background-color: #919eab;
        //box-shadow: inset 0 0 0 1px #919eab;// Cambio a pedido de Nico 2021-03-10
        //color: white;// Cambio a pedido de Nico 2021-03-10

        color: #919eab;
        cursor: not-allowed;
      }
    }

    span {
      height: 39px;
      width: 39px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      //box-shadow: inset 0 0 0 1px #c4c4c4;// Cambio a pedido de Nico 2021-03-10

    }
  }

}

.calendario__month {
  .calendario__nombres, .calendario__dias {
      /* Flexbox */
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      /* Box Model */
      margin:  0;
      padding: 0;

      /* Other */
      list-style-type: none;
  }

  .date {
    /* Variables */
    $dimensions: 37px;
    $day-width: 47px;

    $day-margin: 5px;
    .reserva-de-sala-page & {
      $day-margin: 7px;
      $day-width: 51px;
    }


    /* Flexbox */
    display: flex;
    justify-content: center;
    align-items: center;

    /* Box Model */
    width:  $dimensions;
    height: $dimensions;
    margin: 5px $day-margin;

    /* Typography */
    color: #333333;
    font-size: 14px;
    line-height: 21px;

    /* Other */
    border-radius: 50%;
    //box-shadow: inset 0 0 0 1px rgba(0,0,0,0.3); // Cambio a pedido de Nico 2021-03-10
    cursor: pointer;

    span {
      top: -1px;
    }

    &.is-selected {
        color: white;
        background-color: $background-color--attention;
        box-shadow: inset 0 0 0 1px $background-color--attention;
        font-weight: bold;
    }

    &.is-no-disponible {
      //background-color: #919eab; // Cambio a pedido de Nico 2021-03-10
      //box-shadow: inset 0 0 0 1px #919eab;// Cambio a pedido de Nico 2021-03-10
      //color: white;// Cambio a pedido de Nico 2021-03-10

      color: #919eab;
      cursor: not-allowed;
    }

    &:first-child.day--0 {
        /* Box Model */
        margin-left: $day-margin;
    }
    &:first-child.day--1 {
        /* Box Model */
        margin-left: calc( (#{$day-width} * 1) + #{$day-margin});
    }
    &:first-child.day--2 {
        /* Box Model */
        margin-left: calc( (#{$day-width} * 2) + #{$day-margin});
    }
    &:first-child.day--3 {
        /* Box Model */
        margin-left: calc( (#{$day-width} * 3) + #{$day-margin});
    }
    &:first-child.day--4 {
        /* Box Model */
        margin-left: calc( (#{$day-width} * 4) + #{$day-margin});
    }
    &:first-child.day--5 {
        /* Box Model */
        margin-left: calc( (#{$day-width} * 5) + #{$day-margin});
    }
    &:first-child.day--6 {
        /* Box Model */
        margin-left: calc( (#{$day-width} * 6) + #{$day-margin});
    }
  }

}

.day {
    /* Flexbox */
    flex: 1;

    /* Typography */
    text-align: center;
}

.calendario__dias {
    /* Flexbox */
    flex-wrap: wrap;
}














