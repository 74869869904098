.main-modal {
  display: flex !important;
  justify-content: center;
  align-items: center;

  button.close {
    display: none;
  }

  .modal-dialog {
    //min-width: 80%;
  }

  .modal-content {
    //min-height: 80vh;
  }

  .modal-title {
    text-align: center;
  }
  .main-modal-title-icon {
  }

  .main-modal-title {
    @extend %font-style--title;

    font-size: 16px;
    line-height: 19px;
    font-weight: 400;

    text-transform: uppercase;
    color: $color--attention;
    text-align: center;
    width: 100%;
  }

  .main-modal-subtitle {
    @extend %font-style--title;

    font-size: 16px;
    line-height: 19px;
    font-weight: 400;

    color: $color--tertiary;
    text-align: center;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .main-modal-instructions {
    font-size: 12px;
    line-height: 18px;
    color: $color--tertiary;
    margin-bottom: 24px;
    text-align: center;
    font-family: inherit;
  }

  .main-modal-item {
    font-size: 12px;
    line-height: 18px;
    color: $color--primary;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    p {
      width: 170px;
    }
  }

  .main-modal-icon {
    width: 20px;
    margin-right: 10px;
  }

  .main-modal-footer {
    justify-content: center;
  }

  .main-modal-button {
    background-color: $background-color--secondary;
    color: $color--attention;
    border-color: $color--attention;
    border-style: solid;
    border-width: 1px;
    border-radius: 2px;

    font-size: 12px;
    font-weight: 900;
    text-transform: uppercase;

    width: 224px;
    height: 48px;

    &.btn-primary:active {
      color: $background-color--secondary;
      background-color: $color--attention;
      border-color: $color--attention;
    }
  }
}
