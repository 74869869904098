.mi-oficina-page {

  padding: 0 16px 100px 16px;

  .fixed-CTA__leyenda {
    color: black;
  }

  .desktop & {
    padding: 0 24px 100px 24px;

    .fixed-CTA {
      left: $nav-width--desktop;
      bottom: 0;
      width: calc(100% - #{$nav-width--desktop});
    }

    .oficina-list-item {
      max-width: 800px;
      //margin: 0 auto 28px;
      margin: 24px auto 28px;
    }
  }

  .fixed-CTA {
    .mobile & {
      background: none;
      box-shadow: none;
      .fixed-CTA__control {
        width: 100%;
      }
    }
  }

  .fixed-CTA__label {
    @extend %font-style--attention;
    color: $color--inverted;
  }

  .oficina-list-item {
    margin-bottom: 28px;
  }
}

.mi-oficina-calendario {
  .desktop & {
    max-width: 800px;
    margin: auto;
  }

}

.mi-oficina-listado {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.mi-oficina-listado__item {
  margin-bottom: 16px;
}

.mi-oficina-calendario__titulo {
  @extend %font-style--attention;
  color: $color--primary;

  .desktop & {
    @extend %font-style--title;
    color: $background-color--tertiary;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;

  }
}

.mi-oficina-calendario__parrafo {
  color: $color--secondary;
  font-size: 12px;
  line-height: 18px;

  .desktop & {
    display: inline-block;
    margin-left: 10px;
  }
}

.mi-oficina-control {
  background: none;
  display: block;
  border: none;
  outline: none;
  margin: auto;

  &:focus {
    outline: none;
  }
}

.mi-oficina-control__text {
  /* color: $color--secondary; */
}

.mi-oficina-control__icon {
  transform: rotate(270deg);
  margin-left: 10px;
}