.reservas-page {
    height: css-min(100vh, 100%);

    & .my-week {

        h1 {
            font-size: 24px;

            .desktop & {
                font-size: 24px;
            }
        }

        .event-list {
            /* Flexbox */
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
        }
        .event-item {
            /* Box Model */
            //width: 360px;
        }
    }

    & .my-events {
        .mobile & {
            margin: 16px;
            button[class*="button--reservation-"] {
                border-radius: 10px;
            }
        }
    }

    .wrapper--scrollable-content__layout {
        .desktop & {
            /* Flexbox */
            display: flex;
            flex-direction: row;
            justify-content: center;

            /* Box Model */
            padding: 30px 38px;
        }
    }
    .wrapper--scrollable-content__column {
        &:first-child {
            .desktop & {
                /* Flexbox child */
                flex: 0 0 380px;
            }
        }
        &:last-child {
            .desktop & {
                /* Flexbox child */
                flex: 0 0 415px;

                /* Box Model */
                margin-left: 45px;
            }
        }
    }
}
