.exito-de-experiencia-page {
  position: relative;
  padding: 0 16px 100px;
  //height: calc(100vh - #{$status-height--mobile} - #{$nav-height--mobile});
  width: 100%;

  .exito-section {
    position: relative;
    width: calc(100% + 32px);
    left: -16px;
    height: 120px;
    background-color: $background-color--exito;
    color: $color--inverted;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .desktop & {
      flex-direction: column;
      height: 200px;
      //width: calc(100vw - #{$nav-width--desktop});
      left: -24px;
    }
  }

  .exito__icon {
    width: 64px;
    min-width: 64px;
    height: 64px;
    font-size: 34px;
    line-height: 58px;
    border-width: 4px;
    text-align: center;
    border-style: solid;
    border-radius: 50%;
    margin: 0 16px;

    .desktop & {
      width: 84px;
      height: 84px;
      font-size: 56px;
      line-height: 84px;
      border-width: 3px;
    }
  }

  .exito__titulo {
    /* Typography */
    @extend %font-style--title;
    font-size: 26px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;

    .desktop & {
      text-align: center;
    }

  }

  .exito__creditos {
    margin-bottom: 24px;
  }

  .exito__costo {
    @extend %font-style--attention;
    font-size: 12px;
    font-weight: 900;
    line-height: 18px;
    display: block;
  }

  .exito__leyenda {
    display: block;
    .bold {
      font-weight: 700;
    }
  }


  .detalle-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }

  .detalle-de-experiencia__info {
    width: 80%;
    visibility: visible;
    height: min-content;
    margin-bottom: 12px;
  }

  .detalle-de-experiencia__titulo {
    visibility: visible;
    .desktop & {
      margin: 0;
      margin-bottom: 14px;
    }
  }

  .detalle-de-experiencia__fecha {
    visibility: visible;
  }

  .detalle-de-experiencia__data {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }

  .desktop & {
    padding: 0 24px 100px 24px;
    //height: calc(100vh - #{$status-height--desktop});

  }

  .fixed-CTA {
    .fixed-CTA__control {
      .desktop & {
        width: 230px;
        background-color: $background-color--secondary;
        border-color: $color--attention;
        border-style: solid;
        border-width: 1px;


        & .fixed-CTA__label {
          color: $color--attention;
        }

      }
      .mobile & {
        width: 100%;
      }

    }

    .desktop & {
      & .fixed-CTA__leyenda {
        color: $color--primary;
      }

      left: $nav-width--desktop;
      bottom: 0;
      width: calc(100% - #{$nav-width--desktop});
    }

  }

  .wrapper--scrollable-content__layout {
    .desktop & {
        /* Flexbox */
        display: flex;
        flex-direction: row;
        justify-content: center;

        /* Box Model */
        padding: 30px 38px;
        padding-bottom: 0;
      }
  }
  .wrapper--scrollable-content__column {

    &:first-child {
        .desktop & {
            /* Flexbox child */
            //flex: 0 0 380px;
            max-width: 329px;
        }
    }

    &:last-child {
        .desktop & {
            /* Flexbox child */
            //flex: 0 0 415px;

            /* Box Model */
            margin-left: 45px;
        }
    }

  }
}