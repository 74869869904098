.reserva-de-sala-page {

  padding: 0 16px 100px 16px;

  .calendario {
    margin-top: 16px;
    padding: 1px 0;
    //background: none;
    margin-bottom: 12px;
    width: 360px; // Agregado a pedido de Nico 2021-03-10
    // Se agrega definicion de margenes especiales para el calendario en esta pagina

    .mobile & {
      width: 100%;
    }
  }

  .selector__wrapper {
    margin-bottom: 12px;
    width: 360px; // Agregado a pedido de Nico 2021-03-10

    .mobile & {
      width: 100%;
    }
    .info {
      width: 50px; // Agregado a pedido de Nico 2021-03-26 (para creditos/hora)
    }
  }

  .fixed-CTA__leyenda {
    color: black;
  }

  .desktop & {
    padding: 0 24px 100px 24px;

    .fixed-CTA {
      left: $nav-width--desktop;
      bottom: 0;
      width: calc(100% - #{$nav-width--desktop});
    }

  }

  .wrapper--scrollable-content__layout {
    .desktop & {
        /* Flexbox */
        display: flex;
        flex-direction: row;
        justify-content: center;

        /* Box Model */
        padding: 30px 38px;
      }
  }
  .wrapper--scrollable-content__column {

    .titulo{

      display: none;

      .desktop & {
        display: block;

        /* Typography */
        text-transform: none;
        color: rgba(0, 0, 0, 0.87);
        opacity: 0.7;
        text-transform: uppercase;
        font-family: Adieu, sans-serif;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        margin-bottom: 16px;

      }

    }

    &:first-child {
        .desktop & {
            /* Flexbox child */
            flex: 0 0 380px;
            //max-width: 329px; // Cambiado a pedido de Nico 2021-03-10
            max-width: 360px;
        }
    }

    &:last-child {
        .desktop & {
            /* Flexbox child */
            flex: 0 0 415px;

            /* Box Model */
            margin-left: 45px;
        }
    }

  }

}

.reserva-de-sala__horario-duracion {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  .selector__icon {
    display: none;
  }

  .selector-combo {
    width: 45%;
  }

  .selector__titulo {
    margin-left: 10px;
  }

  .selector__wrapper {
    max-width: 100%;
  }
}

.selector-combo__titulo {
  .reserva-de-sala-page & {
    // Typography
    color: $color--secondary;
    font-size: 12px;
    line-height: 18px;
  }
}

.control--info-de-salas {
  @extend %button--no-background;

  // Typography
  color: $color--attention;
  text-decoration: underline;
}

.reserva-de-sala__info-sala {

  /* Positioning */
  margin: -12px 0 0;

  /* Typography */
  font-size: 12px;
  line-height: 14px;
  text-align: right;

  /* Other */
  color: $color--attention;
  cursor: pointer;


}

.reserva-de-sala__info-sala-button {
  /* Box Model */
  height: 20px;
  //margin:  16px 0 0 ;
  //padding: 12px;
  width: 20px;

  text-align: center;

  /* Other */
  color: $color--attention;
  background-color: $background-color--base;
  border-color: $color--attention;
  border-radius: 50%;
  box-shadow: none;
  border-style: solid;

}