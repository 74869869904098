.comunidad-page {

  .maquinero {
    &:nth-child(odd) {
      .maquinero-card__avatar__image {
        background-color: $background-color--attention;
      }
      .maquinero-card__avatar__text {
        color: $color--inverted;
      }
    }
    &:nth-child(even) {
      .maquinero-card__avatar__image {
        background-color: #6662A4;
      }
      .maquinero-card__avatar__text {
        color: $color--inverted;
      }
    }
  }

}