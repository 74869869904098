.seleccion-de-escritorio-page {

  padding: 0 16px 100px 16px;

  .fixed-CTA__leyenda {
    color: black;
  }

  .desktop & {
    padding: 0 24px 100px 24px;

    .fixed-CTA {
      left: $nav-width--desktop;
      bottom: 0;
      width: calc(100% - #{$nav-width--desktop});
    }

  }

  .selector-mesa {
    margin: 0 auto 16px;
    margin-top: 20px;

  }

  .mesa {
    margin: 60px auto; //espacio para las sillas + centrado horizontal
  }

  .fixed-CTA {
    .mobile & {
      background: none;
      box-shadow: none;
      .fixed-CTA__control {
        width: 100%;
      }
    }
  }

  .fixed-CTA__label {
    @extend %font-style--attention;
    color: $color--inverted;
  }
}

.seleccion-de-escritorio__control {
  width: 100%;
}

.wrapper--scrollable-content__layout {
  .seleccion-de-escritorio-page & {
    .desktop & {
        /* Flexbox */
        display: flex;
        flex-direction: row;
        justify-content: center;

        /* Box Model */
        padding: 30px 38px;
      }

  }
}

.mesa__image {
  .seleccion-de-escritorio-page & {
    .desktop & {
      --var-aspect-width: 16;
      --var-aspect-height: 10;
      // --var-width: 300px;
      // --var-width: 400px;
      --var-width: 400px;
      width: var(--var-width);
      height: calc(var(--var-width)/var(--var-aspect-width)*var(--var-aspect-height));
    }
  }
}

.fullscreen-image {
  .seleccion-de-escritorio-page & {
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
    top: 0 !important;
    left: 0 !important;
    z-index: 1000 !important;
    background-color: aliceblue !important;
  }

}

.sector__image {
  .seleccion-de-escritorio-page & {
    height: 200px;

    .desktop & {
      --var-aspect-width: 16;
      --var-aspect-height: 10;
      // --var-width: 750px;
      --var-width: 600px;
      width: var(--var-width);
      height: calc(var(--var-width)/var(--var-aspect-width)*var(--var-aspect-height));
    }
  }
}

.wrapper--scrollable-content__column {
  .seleccion-de-escritorio-page & {
    &:first-child {
      .desktop & {
          /* Flexbox child */
          // flex: 0 0 380px;
          //max-width: 329px; // Cambiado a pedido de Nico 2021-03-10
          // max-width: 360px;
          margin-inline-end: 20px;
      }
    }
  }
}

.sector__column {
  .seleccion-de-escritorio-page & {
    position: relative;
  }
}

.sector__tour--switch {
  .seleccion-de-escritorio-page & {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.sector__tour--button {
  .seleccion-de-escritorio-page & {
    background: none;
    background-color: $background-color--base + AA;
    border: none;
    box-shadow: 0 0 1px black;
    height: 25px;
    width: 80px;

    &.active {
      background-color: $background-color--attention + AA;
      color: $color--inverted;
    }
  }
}

.image__toggle-wrapper {
  .seleccion-de-escritorio-page & {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image__toggle.MuiSwitch-root {

    width: 42px;
    height: 26px;
    padding: 0;

    & .MuiSwitch-switchBase {
      padding: 0;
      margin: 2px;
      transition-duration: 300ms;

      &.Mui-checked {
        transform: translateX(16px);
        color: #fff;
        & + .MuiSwitch-track {
          background-color: $color--attention;
          opacity: 1;
          border: 0;
        }
        &.Mui-disabled + .MuiSwitch-track {
          opacity: 0.5;
        }
      }
      &.Mui-focusVisible .MuiSwitch-thumb {
        color: $color--attention;
        border: 6px solid #fff;
      }
      &.Mui-disabled .MuiSwitch-thumb {
        color: #eee;
      }
      &.Mui-disabled + .MuiSwitch-track {
        opacity: 0.7,
      }
    }
    & .MuiSwitch-thumb {
      box-sizing: border-box;
      width: 22px;
      height: 22px;
    }
    & .MuiSwitch-track {
      border-radius: calc(26px / 2);
      background-color: $color--attention;
      opacity: 1;
      transition: background-color 500s;
    }
  }
}


