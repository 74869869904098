.qr-page {
  // Flexbox
  display: flex;
  flex-direction: column;
  // Box Model
  height: calc(100vh - #{$status-height--mobile} - #{$nav-height--mobile});
  padding-block-start: 0;
}
.titulo-back {
  .qr-page & {
    // Box model
    padding-inline: 16px;
    // Other
    background-color: $background-color--base;
    z-index: 10;
    .desktop & {
      padding-inline: 24px;
    }
  }
}
.titulo-back__control {
  .qr-page & {
    left: 16px;
  }
}
.wrapper--qr-code {
  // Flexbox
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  // Other
  z-index: 1;
}
.codigo-QR {
  // Other
  box-shadow: 0 0 0 16px white;
}
.instrucciones,
.instrucciones--extra {
  // Box Shadow
  padding-inline: 16px;
  // Typography
  text-align: center;
}
.instrucciones {
  // Box Model
  margin-block-start: 48px;
  // Typography
  font-family: Adieu, sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  text-transform: uppercase;
}
.instrucciones--extra {
  // Box Model
  margin-block-end: 32px;
}