.wrapper--scrollable-content__layout {
    .home-page & {
        /* Flexbox */
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        /* Box Model */
        padding: 30px 38px;
        .desktop & {
            max-width: 1280px;
            margin: auto;
        }
    }
}
.wrapper--scrollable-content__column {
    &:first-child {
        .home-page & {
            /* Flexbox child */
            flex: 0 0 650px;
        }
    }
    &:last-child {
        .home-page & {
            /* Flexbox child */
            flex: 0 0 415px;

            /* Box Model */
            margin-left: 45px;
        }
    }
}