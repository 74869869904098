.sign-in-header {
    /* Variable */
    $background-image: $image-path + '/sign-in-ellipsis.png';

    /* Positioning */
    position: relative;

    /* Box Model */
    height: 89px;
    overflow: hidden;

    /* Other */
    background:
        url($background-image)
        top
        left
        no-repeat;
}
.sign-in-header__image {
    &--01, &--02, &--03, &--04, &--05, &--06 {
        /* Positioning */
        position: absolute;

        /* Box Model */
        display: block;

        /* Other */
        border-radius: 50%;
    }

    &--01 {
        /* Variable */
        $side: 76px;

        /* Positioning */
        top:   13px;
        left: 143px;

        /* Box Model */
        height: $side;
        width:  $side;
    }
    &--02 {
        /* Variable */
        $side: 52px;

        /* Positioning */
        top:   7px;
        left: 16px;

        /* Box Model */
        height: $side;
        width:  $side;
    }
    &--03 {
        /* Variable */
        $side: 57px;

        /* Positioning */
        top:  -22px;
        left: 320px;

        /* Box Model */
        height: $side;
        width:  $side;
    }
}