.login-header {
    /* Variable */
    $background-image: $image-path + '/login-ellipsis.png';

    /* Positioning */
    position: relative;

    /* Box Model */
    height: 325px;
    overflow: hidden;

    /* Other */
    background:
        url($background-image)
        top
        left
        no-repeat;
}
.login-header__logo {
    /* Positioning */
    position: absolute;
    top: 24px;
    left: 50%;
    transform: translateX(-50%);

    /* Box Model */
    width: 61px;
}
.login-header__image {
    &--01, &--02, &--03, &--04, &--05, &--06 {
        /* Positioning */
        position: absolute;

        /* Box Model */
        display: block;

        /* Other */
        border-radius: 50%;
    }

    &--01 {
        /* Variable */
        $side: 80px;

        /* Positioning */
        top:   38px;
        left: -15px;

        /* Box Model */
        height: $side;
        width:  $side;
    }
    &--02 {
        /* Variable */
        $side: 128px;

        /* Positioning */
        top:   91px;
        left: 116px;

        /* Box Model */
        height: $side;
        width:  $side;
    }
    &--03 {
        /* Variable */
        $side: 113px;

        /* Positioning */
        top:   38px;
        left: 290px;

        /* Box Model */
        height: $side;
        width:  $side;
    }
    &--04 {
        /* Variable */
        $side: 86px;

        /* Positioning */
        top:  207px;
        left: -21px;

        /* Box Model */
        height: $side;
        width:  $side;
    }
    &--05 {
        /* Variable */
        $side: 52px;

        /* Positioning */
        top:  273px;
        left: 159px;

        /* Box Model */
        height: $side;
        width:  $side;
    }
    &--06 {
        /* Variable */
        $side: 89px;

        /* Positioning */
        top:  210px;
        left: 308px;

        /* Box Model */
        height: $side;
        width:  $side;
    }
}