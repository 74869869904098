$menu-cover-height: 94px;
$menu-height: 817px;

.menu {
    /* Other */
    background-color: #F9FAFB;
    position: fixed;
    z-index: 10;

    .desktop & {
        left: $nav-width--desktop;
        top: 0;
        padding-top: $status-height--desktop;
        width: 360px;
        height: css-min(#{$menu-height} + #{$status-height--desktop}, 100vh); // referencia a la funcion min css (sin usar min de sass)
        box-shadow: 0px 0px 10px 0px $background-color--primary;
    }

    left: 0;
    top: $status-height--mobile;
    width: 100%;

    height: calc(100vh - (#{$status-height--mobile} + #{$nav-height--mobile}));

}

.menu-scrollable-content {
    position: relative;
    height: calc(100% - #{$menu-cover-height});
    overflow: scroll;
    scroll-behavior: smooth;
}
