.check-multitexto {
  /* Box Model */
  height: 83px;
  width: 328px;

  /* Other */
  background-color: white;
  border: none;
  border-radius: 2px;
  box-shadow: inset 0 0 0 1px #919eab;
  color: #919eab;
  outline: none;

  /* Flex box */
  display: flex;
  flex-direction: row;

  &.is-checked {
    /* Other */
    box-shadow: inset 0 0 0 1px $background-color--attention, 0 4px 8px 0 #dfe3e8;
  }

  &:focus {
    outline: none;
  }

}

.check-multitexto-textos {
  /* Box Model */
  height: 100%;
  padding-left: 16px;

  /* Flex box */
  flex: 1;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  /* Typography */
  text-align: left;

}

.check-multitexto-textos__titulo {
  /* Box Model */
  margin: 0;
  padding: 0;

  /* Typography */
  font-family: Adieu, sans-serif;
  font-size: 20px;
  line-height: 20px;
  color: black;
}

.check-multitexto-textos__descripcion {
  /* Box Model */
  margin: 0;

  /* Typography */
  font-size: 14px;
  line-height: 20px;
}

.check-multitexto-icon {
  /* Box Model */
  height: 100%;

  /* Flex box */
  flex: 0 0 56px;
  display: flex;
  justify-content: center;
  align-items: center;

  /* Other */
  i[class^="icon"] {
    /* Box Model */
    height: 24px;
    width: 24px;

    /* Flex box */
    display: flex;
    justify-content: center;
    align-items: center;

    /* Typography */

    /* Other */
    background-color: #f4f6f8;
    border-radius: 50%;
    box-shadow: inset 0 0 0 1px #919eab;

    &::before {
      /* Box Model */
      display: none;

      /* Typography */
      font-size: 14px;

      .is-checked & {
        /* Box Model */
        display: block;

        /* Typography */
        color: white;
      }
    }

    .is-checked & {
      /* Other */
      background-color: $background-color--attention;
      box-shadow: none;
    }
  }
}