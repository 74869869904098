.oficina-list-item {
  /* Box Model */
  box-sizing: border-box;
  padding: 12px 16px;

  /* Flexbox */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  /* Other */
  background-color: $background-color--secondary;
  box-shadow: 0 0 0 1px #F0F0F0;
  border-radius: 2px;
}

.oficina-list-item__titulo {
  /* Box Model */
  margin: 0;
  padding: 0;

  /* Flexbox */
  flex: 0;
  order: 2;

  /* Typography */
  @extend %font-style--title;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;

}

.oficina-list-item__espacio {
  /* Flexbox */
  flex: 0;
  order: 3;

  /* Typography */
  color: $color--secondary;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.oficina-list-item__sector {
  /* Box Model */
  margin: 0 0 6px;

  /* Flexbox */
  flex: 0;
  order: 1;

  /* Typography */
  color: $color--secondary;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;

  &::before {
    content: "";
    /* Box Model */
    display: inline-block;
    margin-right: 6px;
    height: 18px;
    width: 17px;

    /* Positioning */
    position: relative;
    top: 4px;

    /* Other */
    $imageUrl: $image-path + '/piso.svg';
    background-image: url($imageUrl);
    background-repeat: no-repeat;

  }
}