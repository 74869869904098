/* Variables */
$sign-in-spacing: 16px;

.sign-in-form {
   /* Variables */
   $spacing: $sign-in-spacing;

   /* Box Model */
   padding: $spacing;
}
.sign-in-form__title {
    /* Box Model */
    margin: 3px 0 0;

    /* Typography */
    font-family:      Adieu;
    font-size:         27px;
    font-weight:        700;
    letter-spacing: -0.03em;
    line-height:       36px;
    text-align:      center;
}
.sign-in-form__subtitle {
    /* Variables */
    $spacing: ( $login-spacing / 2 );

    /* Box Model */
    margin:  $spacing 0 0 ;

    /* Typography */
    color:   #637381;
    font-size:    12px;
    line-height:  20px;
    text-align: center;
}
.sign-in-form__social-media-controls {
    /* Variables */
    $spacing: ( $login-spacing / 2 );

    /* Box Model */
    margin:  $spacing 0 ( $spacing * 2 );
    
    /* Flexbox */
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
*[class*="sign-in-form__social-media-control--"] {
    @extend %button--no-background;

    /* Box Model */
    padding: 22px;

    /* Other */
    background-color: white;
    border-radius: 16px;
    box-shadow: 0px 4px 4px #DFE3E8;
}
.sign-in-form__e-mail-controls {
    /* Variables */
    $spacing: ( $sign-in-spacing / 2 );

    /* Box Model */
    margin:  $spacing 0 0;

    /* Flexbox */
    display: flex;
    flex-direction: column;
}
*[class*="sign-in-form__input--"] {
    /* Variables */
    $spacing: $sign-in-spacing;
    
    /* Box Model */
    height:    ( $spacing * 3);
    padding: 0 ( $spacing / 2);
    margin:           0 0 28px;

    /* Other */
    background-color: #F4F6F8;
    border: none;
    border-radius: 4px;
    box-shadow: 0 0 0 1px #C4CDD5;
    outline: none;
}
*[class*="sign-in-form__control--"] {
    /* Box Model */ 
    height: 48px;
    margin: 8px 0 0;
    padding: 15px 0;

    /* Typography */
    font-size:     13px;
    font-weight:    700;
    letter-spacing: 2px;
    line-height:   18px;

    /* Other */
    border: none;
    border-radius: 12px;
}
.sign-in-form__control--sign-in {
    /* Typography */
    color: #919EAB;
    text-transform: uppercase;
    
    /* Other */
    background-color: #C4CDD5;
}
.sign-in-form__control--forgot-password {
    /* Typography */
    color: #212B36;

    /* Other */
    background-color: transparent;
}