/**
* unitless() => boolean, returns true if number is unitless
*/

@function css-min($numbers...) {
  @return #{mi}n($numbers);
}

@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
      @return $number / ($number * 0 + 1);
    }

    @return $number;
  }

@function size($desired-size-in-px, $viewport-width-in-px: 360px) {
    /**
    * 1. We strip $desired-size-in-px of its unit, should it have one
    * 2. We strip $viewport-width-in-px of its unit, should it have one
    * 3. We set $calculated-size to the desired size, expressed as a percentage
    *    of the viewport in 'vw' units, where 1% == 1vw
    */
    $unitless-desired-size: strip-unit($desired-size-in-px);
    $unitless-viewport-width: strip-unit($viewport-width-in-px);

    $calculated-size: ( ( $unitless-desired-size / ( $unitless-viewport-width / 100 ) ) * 1vw );

    @return $calculated-size;
}