.my-events {
    /* Box Model */
    padding: 24px;

    .desktop & {
        max-width: 415px;

    }

    .desktop .home-page & {
        position: relative;
        top: -120px;
    }


    /* Other */
    background-color: white;
    border-radius: 20px;
}