$puesto--height: 91px;
$puesto--width: 94px;

.mesa {
    /* Positioning */
    position: relative;

    /* Box Model */
    height: calc(#{$puesto--height} * 2);
    //margin:  16px 0 0 ;
    //padding: 16px;
    max-width: 100%;
    width: auto;

    // background-color: #ffc7b2;
    border-radius: 8px;
    //overflow: hidden;

    &--2 {
        max-width: calc(#{$puesto--width} * 1);
    }
    &--4 {
        max-width: calc(#{$puesto--width} * 2);
    }
    &--6 {
        max-width: calc(#{$puesto--width} * 3);
    }
    &--8 {
        max-width: calc(#{$puesto--width} * 4);
    }

}

.mesa__puestos {
    /* Box Model */
    height: 100%;
    margin: 0 ;
    padding: 0;
    width: 100%;

    /* Flex box */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    /* Other */
    list-style-type: none;

}

.mesa__puesto {
    height: $puesto--height;
    width: 100%;

    .mesa--2 & {
        max-width: calc(100% / 1);
    }
    .mesa--4 & {
        max-width: calc(100% / 2);
    }
    .mesa--6 & {
        max-width: calc(100% / 3);
    }
    .mesa--8 & {
        max-width: calc(100% / 4);
    }

    .escritorio {
        width: 100%;
    }

    .desktop & {
        max-width: $puesto--width;
    }
}
