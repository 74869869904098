/* Variables */
$login-spacing: 16px;

.login-CTA {
    /* Variables */
    $spacing: $login-spacing;

    /* Box Model */
    margin:  $spacing 0 0 ;
    padding: $spacing;
}
.login-CTA__title {
    /* Variables */
    $spacing: ( $login-spacing / 2 );

    /* Box Model */
    margin:  $spacing 0 0 ;

    /* Typography */
    font-size:         27px;
    font-weight:        700;
    letter-spacing: -0.03em;
    line-height:       36px;
}
.login-CTA__subtitle {
    /* Variables */
    $spacing: ( $login-spacing / 2 );

    /* Box Model */
    margin:  $spacing 0 0 ;

    /* Typography */
    color: #637381;
    font-size:   13px;
    line-height: 20px;
}
.login-CTA__controls {
    /* Variables */
    $spacing: $login-spacing;

    /* Flexbox */
    display: flex;
    flex-direction: column;

    /* Box Model */
    margin:  $spacing 0 0 ;
}
*[class*="login-CTA__control--"] {
    /* Box Model */ 
    height: 48px;
    margin: 8px 0 0;
    padding: 15px 0;

    /* Typography */
    text-transform: uppercase;
    font-size:     13px;
    font-weight:    700;
    letter-spacing: 2px;
    line-height:   18px;

    /* Other */
    border: none;
    border-radius: 12px;
}
.login-CTA__control--register-account {
    /* Typography */
    color: white;
    
    /* Other */
    background-color: #FE6C34;
}
.login-CTA__control--sign-in {
    /* Typography */
    color: #212B36;

    /* Other */
    background-color: transparent;
}