.perfil-page {
  $page-padding-inline: 16px;
  $page-padding-bottom: 100px;

  padding-inline: $page-padding-inline;
  padding-block-end: $page-padding-bottom;

  .perfil-section {
    position: relative;
    margin-top: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .desktop & {
      background-color: white;
      padding: calc(#{$page-padding-inline} * 2);
      box-shadow: 0px 0px 10px 0px $background-color--primary;
      border-radius: 2px;
    }
  }

  .input-label-combo {
    width: 100%;
  }

  .titulo {
    width: 100%;
  }

  .selector__wrapper {
    margin-bottom: 12px;
    width: 100%;
  }

  .fixed-CTA__leyenda {
    color: black;
  }

  .desktop & {
    padding: 0 24px 100px 24px;

    .fixed-CTA {
      left: $nav-width--desktop;
      bottom: 0;
      width: calc(100% - #{$nav-width--desktop});
      justify-content: center;

    }

  }

  .fixed-CTA__control {
    width: 300px;
  }

  .mobile & {
    .fixed-CTA__control {
      width: 100%;
    }
  }

  .wrapper--scrollable-content__layout {
    .desktop & {
        /* Flexbox */
        display: flex;
        flex-direction: row;
        justify-content: center;

        /* Box Model */
        padding: 30px 38px;
      }
  }
  .wrapper--scrollable-content__column {

    &:first-child {
        .desktop & {
            /* Flexbox child */
            flex: 1;
        }
    }

    &:last-child {
        .desktop & {
            /* Flexbox child */
            flex: 1;

            /* Box Model */
            margin-left: 119px;
        }
    }

  }

  .titulo {
    margin-top: 24px;

    font-size: 18px;
    font-weight: 700;


    &:first-child {
      margin-top: 0;
    }

    .desktop & {
      @extend %font-style--title;
      margin-top: 56px;
      margin-bottom: 14px;
      font-weight: 400;

      &:first-child {
        margin-top: 0;
      }
    }

  }

  .text-area {
    width: 100%;
    min-height: 10em;
  }

  .button--user-avatar {
    .desktop & {
      position: absolute;
      top: -40px;
    }
  }

  .button--user-avatar__image {
    width: 74px;
    height: 74px;
  }

  .button--user-avatar__notification-icon {
    top: unset;
    bottom: 0px; // Magic Number -- FIX
    right: 0px; // Magic Number -- FIX
    box-shadow: none;
    font-size: 20px;
    height: 24px;
    width: 24px;
    color: $color--inverted;
    background-color: $background-color--attention;
  }

  .membresia-wrapper {
    background-color: $background-color--secondary;
    border: none;
    border-radius: 2px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
    padding: 24px;
    margin-top: 24px;

    .desktop & {
      // width: 360px;
    }

  }

  .membresia {
    background: none;
    box-shadow: none;
    padding: 0;
    margin: 0;
    margin-top: 16px
  }

  .membresia__titulo {
    @extend %font-style--title;
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-weight: 400;

    .desktop & {
      font-size: 20px;
    }

  }

  .aptitudes {
    display: flex;
    flex-wrap: wrap;
  }

  .aptitud {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 2px solid $color--attention;
    border-radius: 12px;
    background-color: $color--inverted;
    color: $color--attention;
    padding: 5px;
    // margin: 2px auto;
    line-height: 1.2rem;
    font-size: 1rem;
    margin: 3px
  }

  .aptitud-button {
    position: relative;
    border: none;
    outline: none;
    background: none;
    color: inherit;
    line-height: inherit;
    height: calc(100% + 10px);
    margin-inline-start: 3px;
    width: 22px;
    top: 1px; // Ajuste visual
    // right: calc(0px - 5px);
  }

  .aptitud-button--nuevo {
    position: relative;
    outline: none;
    background: none;
    border: 2px solid $color--attention;
    border-radius: 50%;
    background-color: $color--inverted;
    color: $color--attention;
    line-height: 1rem;
    font-size: 1rem;
    margin-left: 10px;
    padding: 0;

    height: 40px;
    aspect-ratio: 1;
  }

  .aptitudes-combo {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 12px;
  }

  .aptitudes--selector {
    margin-bottom: 0px;
    .mobile & {
      max-width: calc(100vw - 10px - 40px - #{$page-padding-inline} * 2);
    }
  }

}

.tipo-de-perfil--selector {
  // Flex
  display        : flex;
  flex-direction : row;
  justify-content: center;
  // Box Model
  margin-block  : 20px 0px;
  margin-inline : auto;
  padding-block : 0;
  padding-inline: 0;
  // Other
  box-shadow: 0 1px 2px -1px #0003;
}
.selector-link {
  .tipo-de-perfil--selector & {
    // Box Model
    margin-inline : 20px;
    padding-block : 10px;
    padding-inline: 20px;
    // Typography
    color         : hsl(173, 0%, 0%);
    cursor        : pointer;
    font-size     : 18px;
    font-weight   : normal;
    letter-spacing: 1px;
    line-height   : 22px;

    &.is--selected {
      // Typography
      font-weight: bold;
      // Other
      box-shadow: inset 0 -3px 0 0 $background-color--attention;
    }
  }
}
.lista-de-empleados {
  // Box Model
  padding: 0;
  margin : 0;
  width  : 100%;
  // List
  list-style-type: none;
}
.tarjeta-de-empleado,
%tarjeta-de-empleado {
  .lista-de-empleados & {
    // Position
    position: relative; // Positioning context
    // Flexbox
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    // Box Model
    // margin-block-start: 16px;
    padding           : 16px;
    // Other
    background-color: white;
    box-shadow      : inset 0 1px 0 0 #ddd;

    &:last-child {
      // Other
      box-shadow: inset 0 -1px 0 0 #ddd, inset 0 1px 0 0 #ddd;
    }
  }
}
.combo--nombre-apellido {
  .tarjeta-de-empleado & {
    @extend %font-style--title;
    // Typography
    font-size  : 16px;
    line-height: 16px;
    font-weight: 400;
  }
}
// .nombre { }
// .apellido { }
.puesto {
  .tarjeta-de-empleado & {
    // Box Model
    display: block;
    // Typography
    color         : $color--attention;
    font-size     : 10px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}
.email {
  .tarjeta-de-empleado & {
    // Box Model
    display           : block;
    margin-block-start: 5px;
    // Typography
    font-size  : 14px;
    line-height: 14px;

    // Hackeo loco para lidiar con e-mails largos
    max-width    : 50vw;
    overflow     : hidden;
    text-overflow: ellipsis;
    white-space  : nowrap;
  }
}
.layout--controles {
  .tarjeta-de-empleado & {
    // Flexbox
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: center;
  }
}
.control--remover-miembro,
.control--aceptar-staff,
.control--cancelar-staff,
.control--agregar-staff {
  // Reset
  outline: none;
  border :  0;
  margin :  0;
  // Box Model
  display       : inline-block;
  padding-block : 10px;
  padding-inline: 14px;
  // Typography
  color         : $color--attention;
  font-size     : 13px;
  line-height   : 13px;
  text-align    : left;
  text-transform: uppercase;
  // Other
  border-radius   : 2px;
  background-color: transparent;
  box-shadow      : 0 0 0 1px $color--attention;

  &:hover {
    // Typography
    color: white;
    // Other
    background-color: $color--attention;
  }
}
.control--agregar-staff {
  // Box Model
  width: 100%;
  // Typography
  color         : white;
  font-weight   : bold;
  letter-spacing: 2px;
  line-height   : 16px;
  text-align    : center;
  // Other
  background-color: $color--attention;
}
.control--aceptar-miembro,
.control--rechazar-miembro {
  // Reset
  outline: none;
  border :  0;
  margin :  0;
  padding:  0;
  // Flexbox
  display        : flex;
  justify-content: center;
  align-items    : center;
  // Box Model
  display      : inline-block;
  aspect-ratio : 1 / 1;
  height       : 42px;
  margin-inline: 5px;
  // Other
  border-radius   : 50%;
  background-color: transparent;
}
.control--aceptar-miembro {
  // Typography
  color: $color--success;
  // Other
  box-shadow: 0 0 0 1px $color--success;

  &:hover {
    // Typography
    color: white;
    // Other
    background-color: $color--success;
  }
}
.control--rechazar-miembro {
  // Typography
  color: $color--warning;
  // Other
  box-shadow: 0 0 0 1px $color--warning;

  &:hover {
    // Typography
    color: white;
    // Other
    background-color: $color--warning;
  }
}
.tarjeta-agregar-staff {
  @extend %tarjeta-de-empleado;

  .lista-de-empleados & {
    // Flexbox
    flex-direction: column;
  }
}
.titulo {
  .desktop .tarjeta-agregar-staff & {
    // Box Model
    margin-block-end: 9px;
    // Typography
    font-size  : 18px;
    font-weight: 400;

    .desktop & {
      // Typography
      font-weight: 700;
    }
  }
}
.layout--row {
  .tarjeta-agregar-staff & {
    // Flexbox
    display       : flex;
    flex-direction: column;
  }
  .desktop .tarjeta-agregar-staff & {
    // Flexbox
    flex-direction: row;
    align-items: flex-start;
  }
}
.layout--column {
  .tarjeta-agregar-staff &:first-child {
    // Flexbox
    flex: 1;

    .desktop & {
      // Box Model
      margin-inline-end: 14px;
    }
  }
}
.layout--controles {
  .tarjeta-agregar-staff & {
    // Flexbox
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: flex-end;
    // Box Model
    margin-block-start: 14px;

    .desktop & {
      // Flexbox
      justify-content: center;
      // Box Model
      margin-block-start: 0;
    }
  }
}
.selector__wrapper {
  .tarjeta-agregar-staff & {
    // Box Model
    margin-bottom: 0;
  }
}
.selector {
  .tarjeta-agregar-staff & {
    // Box Model
    height: 42px; // Same height as aceptar / cancelar buttons
    // Other
    box-shadow: 0 0 0 1px #f0f0f0;
  }
}