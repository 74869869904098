@charset 'utf-8';

// ABSTRACTS
@import "abstracts/_paths";
@import "abstracts/_functions";

// VENDOR
@import "vendors/_normalize";
@import "vendors/_main";
@import "vendors/fontawesome/scss/_fontawesome";
@import "vendors/fontawesome/scss/_solid";

// THEME
@import "themes/_base";

// BASE
@import "base/_typography";
@import "base/_elements";

// LAYOUT
@import "layout/main-view";
@import "layout/_home-page";
@import "layout/_mobile-app";
@import "layout/_login-page";
@import "layout/_sign-in-page";
@import "layout/menu-modal";
@import "layout/experiences-page";
@import "layout/reservas-page";
@import "layout/comunidad-page";
@import "layout/posicion-page";
@import "layout/detalle-de-experiencia-page";
@import "layout/seleccion-de-escritorio-page";
@import "layout/confirmar-page";
@import "layout/exito-page";
@import "layout/seleccion-de-oficina-page";
@import "layout/mi-oficina-page";
@import "layout/reserva-de-sala-page";
@import "layout/onboarding-page";
@import "layout/cancelar-page";
@import "layout/exito-de-experiencia-page";
@import "layout/perfil-page";
@import "layout/creditos-page";
@import "layout/historial-page";
@import "layout/ayuda-page";
@import "layout/detalle-de-maquinero-page";
@import "layout/transacciones-page";
@import "layout/qr-page";
@import "layout/beneficios-page";
@import "layout/detalle-de-beneficio-page";
@import "layout/info-de-salas-page";

// COMPONENTS
@import "components/_icons";
@import "components/_status-bar";
@import "components/_button";
@import "components/_button--user-avatar";
@import "components/_primary-navigation";
@import "components/_navigation-list";
@import "components/_image-slider";
@import "components/_quick-access";
@import "components/_scrollable-content";
@import "components/_my-week";
@import "components/_experiences-spotlight";
@import "components/_experiences-showcase";
@import "components/experiences-categories-list";
@import "components/_experiences-list";
@import "components/_login-header";
@import "components/_login-CTA";
@import "components/_sign-in-header";
@import "components/_sign-in-form";
@import "components/_my-events";
@import "components/_reservations";
@import "components/menu-cover";
@import "components/menu-perfilCTA";
@import "components/menu-list";
@import "components/comunidad-showcase";
@import "components/comunidad-list";
@import "components/comunidad-categories-list";
@import "components/maquinero-card";
@import "components/reservaDePosicion-turno";
@import "components/selector";
@import "components/selector-list";
@import "components/titulo-back";
@import "components/fixed-CTA";
@import "components/calendario";
@import "components/mesa";
@import "components/escritorio";
@import "components/boton-calendario";
@import "components/oficina-list-item";
@import "components/calendario-de-salas";
@import "components/check-multitexto";
@import "components/paquete-de-creditos";
@import "components/membresia";
@import "components/error-modal";
@import "components/main-modal";
@import "components/beneficio-card";
@import "components/beneficios-showcase";
@import "components/beneficios-list";
@import "components/beneficios-categories-list";
@import "components/contrato-card";
