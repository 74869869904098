.beneficios-categories-list {
    /* Flexbox */
    display: flex;
    flex-direction: row;

    /* Box Model */
    margin: 16px 0 0;
    padding:       0;
    width: 100%;
    overflow-x: scroll;

    /* Typography */
    //font-family: 'Rubik', sans-serif;

    /* Other */
    list-style-type: none;

    .desktop & {
        /* Box Model */
        margin: 21px 0 0;
        overflow-x: hidden;
    }
}
.beneficios-type {
    /* Box Model */
    margin-left: 8px;

    &:first-child {
        /* Box Model */
        margin-left: 0;
    }
}
.beneficios-type__selector {
    /* Box Model */
    padding: 6px 8px;

    /* Typography */
    font-size: 14px;
    line-height: 17px;
    white-space: nowrap;

    /* Other */
    background-color: white;
    border: none;
    border-radius: 26px;
    outline: none;

    .beneficios-type.is-active & {
        /* Typography */
        color: white;

        /* Other */
        background-color: $background-color--attention;
    }
}