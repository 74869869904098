$orange: $color--attention;

.fixed-CTA {
    /* Positioning */
    position: fixed;
    bottom: 48px;
    left: 0;
    z-index: 20;

    /* Box Model */
    height: 80px;
    //margin:  16px 0 0 ;
    padding: 16px;
    width: 100%;

    /* Flex box */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    background-color: white;
    //box-shadow: 0 -1px 5px 0 rgba(0, 0, 0, 0.3); // Cambiado a pedido de Nico 2021-03-10
    box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.1);

}
.fixed-CTA__titulo {
    /* Box Model */
    margin:  7px 0 0 0;

    /* Typography */
    color: $orange;
    font-size:         12px;
    font-weight:        900;
    letter-spacing: -0.04em;
    line-height:       18px;
    text-transform: uppercase;
}

.fixed-CTA__leyenda {
    /* Box Model */
    margin:  16px 0 0 ;

    &.bold {
        font-weight: 900;
    }

    /* Typography */
    color: #637381;
    font-size:   12px;
    line-height: 18px;

    .sin-leyenda & {
        display: none;
    }

}
.fixed-CTA__control {
    /* Flexbox */
    display: flex;
    flex-direction: column;

    /* Box Model */
    height: 48px;
    width: 148px;
    //margin: 8px 0 0;
    padding: 15px 0;

    /* Typography */
    text-align: center;

    /* Other */
    background-color: $orange;
    border: none;

    &:disabled {
        background-color: #919eab;
    }

    .CTA__comprar & {
        width: 200px;
    }

}

.fixed-CTA__label {
    /* Box Model */
    width: 100%;

    /* Typography */
    color: $color--inverted;
    text-transform: uppercase;
    font-size:     12px;
    font-weight:    700;
    letter-spacing: 2px;
    line-height:   18px;
}
