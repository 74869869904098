.selector-list {
  /* Box Model */
  //height: 40px;
  width: 100%;

  /* Other */
  background-color: white;
  border: none;
  border-radius: 2px;
  box-shadow: inset 0 0 0 1px #919eab;
  color: #919eab;

  /* Flex box */
  display: flex;
  flex-direction: column;

  /* Typography */
  font-size: 16px;
  line-height: 19px;

  &:focus {
    outline: none;
  }

  overflow: hidden;
  transition: height 0.3s ease-in-out;

}

.selector-list-header {
  /* Box Model */
  height: 40px;
  //width: 100%;
  padding: 0 16px;

  /* Other */
  //background-color: white;
  background: none;
  border: none;
  //border-radius: 2px;
  //box-shadow: inset 0 0 0 1px #919eab;
  color: #919eab;

  /* Flex box */
  display: flex;
  flex-direction: row;

  &:focus {
    outline: none;
  }

}

.selector-list__titulo {
  /* Box Model */
  height: 100%;
  padding: 0;
  margin: 0;

  /* Flex box */
  flex: 1;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  /* Typography */
  color: #919eab;
  font-size: 16px;
  text-align: left;

}

.selector-list__control {
  /* Box Model */
  height: 100%;

  /* Flex box */
  flex: 0 0 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;

  /* Other */
  //background-color: white;
  background: none;
  border: none;
  transform: rotate(90deg);

  &:focus {
    outline: none;
  }
}

.selector-list__items {
  list-style: none;
  padding: 0;
  margin: 0;
}

.selector-item {
  height: 40px;
  width: 328px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  border: none;
  background: none;
  margin: 0;
  padding: 0 16px;
  &:focus {
    outline: none;
  }

  .info {
    /* Typography */
    color: #919eab;
    font-size: 14px;
    text-align: right;

  }

  .mobile & {
    width: 100%;
  }

}

.selector-item__details {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.selector-item__titulo {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;

  .is-checked & {
    color: $color--attention;
  }
}

.selector-item__icon {
  /* Box Model */
  //height: 100%;

  /* Flex box */
  //flex: 0 0 56px;
  //display: flex;
  display: inline-block;
  //justify-content: center;
  //align-items: center;

  /* Other */
  i[class^="icon"] {
    /* Box Model */
    height: 24px;
    width: 24px;

    /* Flex box */
    display: flex;
    justify-content: center;
    align-items: center;

    /* Typography */

    /* Other */
    //background-color: #f4f6f8;
    //border-radius: 50%;
    //box-shadow: inset 0 0 0 1px #919eab;

    &::before {
      /* Box Model */
      display: none;

      /* Typography */
      font-size: 14px;
      color: $color--attention;

      .is-checked & {
        /* Box Model */
        display: block;

        /* Typography */
        //color: white;
      }
    }

    .is-checked & {
      /* Other */
      //background-color: #f6691d;
      //box-shadow: none;
    }
  }
}