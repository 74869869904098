.selector__wrapper {
  width: 328px;

  .mobile & {
    width: 100%;
  }

}

.selector {
  /* Box Model */
  height: 40px;
  width: 100%;

  /* Other */
  background-color: white;
  border: none;
  border-radius: 2px;
  box-shadow: inset 0 0 0 1px #f0f0f0;
  color: #919eab;
  outline: none;

  /* Flex box */
  display: flex;
  flex-direction: row;
  align-items: center;

  &:focus {
    outline: none;
  }

}

.selector-chevron {
  /* Box Model */
  width: 6px;
}

.selector__titulo {
  /* Box Model */
  height: 100%;
  padding: 0;
  margin: 0;

  /* Flex box */
  flex: 1;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  /* Typography */
  color: $color--attention;
  font-size: 16px;
  text-align: left;

}

.selector__info {
  /* Typography */
  color: #919eab;
  font-size: 14px;
  text-align: right;

}

.selector__icon {
  /* Box Model */
  height: 100%;

  /* Flex box */
  flex: 0 0 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  visibility: hidden; max-width: 0px; margin-left: 10px; // Fix para ocultar el icono hasta tener los nuevos

  /* Other */
  i[class^="icon"] {
    /* Box Model */
    height: 24px;
    width: 24px;

    /* Flex box */
    display: flex;
    justify-content: center;
    align-items: center;

    /* Typography */

    /* Other */
    //background-color: #f4f6f8;
    //border-radius: 50%;
    //box-shadow: inset 0 0 0 1px #919eab;

    &::before {
      /* Box Model */

      /* Typography */
      font-size: 16px;

    }

  }
}

.selector__folded {
  /* Box Model */
  height: 100%;

  /* Flex box */
  flex: 0 0 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;

  /* Other */
  transform: rotate(270deg);

  .is-unfolded & {
    /* Other */
    transform: rotate(90deg);
  }
}