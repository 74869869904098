.boton-calendario {
  @extend %button--no-background;
  @extend %button--icon-only;

  height: 70px;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border: $color--secondary solid 1px;
  border-radius: 8px;

  background-color: white;

  padding: 16px;

  .event-item__day {
    height: auto;

    color: inherit;
    background: none;

    font-size: 12px;
  }
  .event-item__date-number {
    height: auto;

    color: inherit;
    background: none;

    font-size: 18px;
  }
}

.boton-calendario__control {
  border: black solid 1px;
  border-radius: 50%;
  height: 24px;
  width: 24px;

  background-color: $background-color--disabled;
  color: $color--inverted;

  i {
    visibility: hidden;
    font-size: 14px;
  }

  .disponible & {
  //  background-color: $color--secondary;
  }

  .noDisponible & {
    visibility: hidden;
  }

  .reservado & {
    i {
      visibility: visible;
      color: $color--secondary;
    }
  }

  .is-checked & {
    background-color: $background-color--attention;
    color: $color--inverted;
    i {
      visibility: visible;
    }
  }
}

.boton-calendario__reservas {
  padding: 0;
  margin: 0;

  list-style-type: none;

  flex: 1;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .attendee:first-child {
    margin-left: 0;
  }

  .attendee {
    margin-left: -10px;
    &:nth-child(odd) {
      .attendee-avatar {
        background-color: #ffe8dc;
        color: $color--attention;
      }
    }

    &:nth-child(even) {
      .attendee-avatar {
        background-color: #55d8a6;
        color: #c2eed0;
      }
    }
  }

  .attendee-avatar {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 40px;
    width: 40px;

    border: none;
    border-radius: 50%;

  }

  .attendee-avatar__text {
    position: relative;
    top: -1px;
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
  }
}