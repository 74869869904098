.button--user-avatar {
    @extend %button--no-background;
    @extend %button--icon-only;

    .primary-navigation & {
        /* Box Model */
        height: 100%;
        width:  100%;

    }

    &:focus {
        /**
        * DEBUG DEBUG
        *
        * It is important to reintroduce some :focus styles to help with
        * tab-navigation.
        *
        * DEBUG DEBUG
        */
        outline: none;
    }
}
.button--user-avatar__content-wrapper {
    /**
    * Positioning
    * -----------
    * We create a positioning context to place the inner elements
    *
    */
    position: relative;

    /**
    * Box Model
    * ---------
    * We make sure the size of the wrapper is determined by its contents
    */
    display: inline-block;
}
.button--user-avatar__image {
    /* Variables */
    $dimensions: 33px;

    /* Box Model */
    display: inline-block;
    height: $dimensions;
    width:  $dimensions;

    /* Flexbox */
    display: flex;
    justify-content: center;
    align-items: center;

    /* Other */
    background-color: #6662A4;
    border: none;
    border-radius: 50%;

    .primary-navigation & {
        /* Variables */
        $dimensions: 48px;

        /* Box Model */
        height: $dimensions;
        width:  $dimensions;
    }
}
.button--user-avatar__text {
    position: relative;
    top: -1px;
    left: 1px;
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    color: $color--inverted;
}
.button--user-avatar__notification-icon {
    /* Variable */
    $color: red;
    $border-color: white;

    /* Positioning */
    position: absolute;
    top:    2px; // Magic Number -- FIX
    right: -2px; // Magic Number -- FIX

    /* Font Awesome */
    color: $color;
    font-size: 8px;

    /* Other */
    border-radius: 50%;
    box-shadow: 0 0 0 2px $border-color;

    .primary-navigation & {
        /* Font Awesome */
        font-size: 11px;
    }
}