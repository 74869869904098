.membresia {
  /* Box Model */
  // height: 83px;
  width: 328px;

  padding: 16px;

  /* Other */
  background-color: $background-color--secondary;
  border: none;
  border-radius: 2px;
  box-shadow: inset 0 0 0 1px #919eab;
  color: #919eab;
  outline: none;

  /* Flex box */
  display: flex;
  flex-direction: row;

}

.membresia-textos {
  /* Box Model */
  height: 100%;
  //padding-left: 16px;

  /* Flex box */
  flex: 1;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  /* Typography */
  text-align: left;
  color: $color--tertiary;
  font-size: 14px;

}

.membresia-textos__titulo {
  /* Box Model */
  margin: 0;
  padding: 0;
  margin-bottom: 10px;

  /* Typography */
  font-size: 17px;
  font-weight: 700;
  line-height: 23px;
  color: $color--attention;

}

.membresia-textos__creditos {
  /* Box Model */
  margin: 0;

  /* Typography */
  @extend %font-style--title;
  font-size: 24px;
  line-height: 29px;
  font-weight: 400;
}

.membresia-textos__descripcion {
  /* Box Model */
  margin: 0;

  /* Typography */
  font-size: 14px;
  line-height: 21px;
}

.membresia-icon {
  /* Box Model */
  height: 100%;

  /* Flex box */
  flex: 0 0 56px;
  display: flex;
  justify-content: center;
  align-items: center;

  /* Other */
  i[class^="icon"] {
    /* Box Model */
    height: 24px;
    width: 24px;

    /* Flex box */
    display: flex;
    justify-content: center;
    align-items: center;

    /* Typography */

    /* Other */
    background-color: #f4f6f8;
    border-radius: 50%;
    box-shadow: inset 0 0 0 1px #919eab;

    &::before {
      /* Box Model */
      display: none;

      /* Typography */
      font-size: 14px;

      .is-checked & {
        /* Box Model */
        display: block;

        /* Typography */
        color: white;
      }
    }

    .is-checked & {
      /* Other */
      background-color: $background-color--attention;
      box-shadow: none;
    }
  }
}