.exito-page {

  padding: 0 16px;
  background-color: $background-color--exito;
  height: calc(100vh - #{$status-height--mobile} - #{$nav-height--mobile});
  width: 100%;
  color: $color--inverted;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .exito__icon {
    width: 84px;
    height: 84px;
    font-size: 40px;
    text-align: center;
    line-height: 84px;
    border-width: 4px;
    border-style: solid;
    border-radius: 50%;
  }

  .exito__titulo {
    /* Typography */
    @extend %font-style--title;
    font-size: 26px;
    font-weight: 400;
    line-height: 31px;

    text-align: center;
  }

  .exito__leyenda {

    .bold {
      font-weight: 700;
    }
  }


  .desktop & {
    padding: 0 24px;
    height: calc(100vh - #{$status-height--desktop});
  }

  .exito__control {
    border: none;
    border-radius: 10px;
    height: 48px;
    padding: 14px;
    background-color: white;
    margin-top: 40px;
    width: 100%;

    @extend %font-style--attention;
    color: black;


    .desktop & {
      width: 328px;
    }
  }
  .exito__control-secundario {
    border: none;
    border-radius: 10px;
    height: 48px;
    padding: 0;
    background: none;
    margin-top: 24px;
    width: 100%;

    @extend %font-style--attention;
    color: white;

    .desktop & {
      width: 328px;
    }
  }
}