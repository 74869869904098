.image-slider {
    padding: 16px;

    .desktop & {
        padding: 24px;
    }
    &--placeholder {
        /* Variables */
        $height: 140px;
        $height--desktop: 256px;
        $background-image: $image-slider__placeholder-image;

        /* Flexbox */
        display: flex;
        flex-direction: column;
        justify-content: center;

        /* Box Model */
        height: $height;

        /* Other */
        background:
            linear-gradient(
                to right,
                rgba(246,105,29,1),
                rgba(246,105,29,0)
            ),
            url($background-image)
            no-repeat;

            /* Magic Numbers -- For Debug ONLY */
            background-position: 25% 30%;
            background-size: 150%;

        .desktop & {
            /* Grid */
            grid-column-start: main-start;
            grid-column-end:     main-end;

            /* Box Model */
            height: $height--desktop;
        }
    }

    &.no-slider {
        //background-size: 100%;
        background-size: cover; // Pedido por Nico 2021-03-17
        .desktop & {
            height: 256px;
        }
        background-position: 0 0;
        background-repeat: no-repeat;
      }
}
.image-slider__slide-text {
    /* Box Element */
    height: 70px;
    width: 180px;
    margin: 0;
    padding: 0;

    .desktop & {
        padding: 17px;
    }

    /* Typography */
    @extend %font-style--title;

    &.cover-title {
        font-size: 20px;

        .desktop & {
            font-size: 36px;
        }
    }
}