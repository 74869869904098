/* Variables */
$spacing:     16px;
$sideSpacing: 38px;

.comunidad-showcase {
    /* Box Model */
    // margin-top:  ( $spacing * 2 );
    padding: ( $spacing ) $spacing;

    /* Typography */
    //font-family: 'Rubik', sans-serif;

    /* Other */

    /* background-image: linear-gradient(
        180deg,
        #B0ADD7 0%,
        rgba(191, 186, 218, 0.807292) 0.01%,
        #FFFFFF 100%
        );
    */

    background-color: #fafafa;

    .desktop & {
        /* Box Model */
        padding: ( $spacing * 2 ) $sideSpacing;
    }
}
.comunidad-showcase__title {
    /* Box Model */
    margin: 0;

    /* Typograhpy */
    font-family: Adieu, sans-serif;
    font-size: 18px;
    line-height: 22px;
}
.comunidad-list {
    .comunidad-showcase & {
        /* Box Model */
        margin-top: 8px;

        .desktop & {
            /* Box Model */
            margin-top: 15px;
        }

    }
}

.comunidad-buscar {
    $color: #bdbdbd;

    width: 100%;
    height: 48px;
    position: relative;

    .desktop & {
        width: 360px;
    }

    i {
        position: absolute;
        left: 12px;
        top: 14px;
        font-size: 20px;
        color: $color;
        z-index: 20;
    }

    input {
        width: 100%;
        height: 100%;
        outline: none;
        box-shadow: inset 0 0 0 1px $color;
        padding: 14px 14px 14px 40px;
        font-size: 14px;
        line-height: 20px;
        color: $color;
        border: none;
        border-radius: 4px;
    }
}

.comunidad-inputs {
    display: flex;

    .desktop & {
        align-items: center;
    }
    .mobile & {
        flex-direction: column;
    }
}

.aptitudes-combo {
    .comunidad-showcase & {
        display: flex;
        .desktop & {
            padding-inline: 20px;
        }
        .mobile & {
            padding-block: 14px;
        }
    }
}

.aptitud-button {
    @extend .button--no-background;
    border: 1px solid;
    border-radius: 50%;
    color:$color--attention;
    width: 40px;
    height: 40px;
    margin-inline-start: 10px;
}

.maquinero {
    .comunidad-showcase & {
        .desktop & {
            /* Flexbox Child */
            flex: 0 0 185px;
        }

        .mobile & {
            .maquinero-card {
                width: 44.5vw;
            }
        }
    }
}
.comunidad-showcase__controls {
    /* Flexbox */
    display: flex;
    flex-direction: row;
    justify-content: center;

    /* Box Model */
    margin-top: ( $spacing * 2 );
}
.comunidad-showcase__more-comunidad {
    @extend %button--no-background;

    /* Box Model */
    margin:  0;
    padding: 0;

    /* Typography */
    color: $color--attention;
    text-decoration: none;
    font-size:   14px;
    line-height: 17px;
    text-align: center;

    i {
        /* Box Model */
        margin-left: ( $spacing / 2 );

        /* Typography */
        font-size: 9px;
        line-height: 17px;
        vertical-align: bottom; // DEBUG DEBUG Magic Number?? DEBUG DEBUG
    }
}