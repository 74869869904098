.seleccion-de-oficina-page {

  padding: 0 16px 100px 16px;

  .fixed-CTA__leyenda {
    color: black;
  }

  .desktop & {
    padding: 0 24px 100px 24px;

  }

}

.seleccion-de-oficina__listado {
  list-style-type: none;
  padding: 0;
  margin: 0;

  .desktop & {
    max-width: 800px;
    margin: auto;
    margin-top: 24px;
  }

  .oficina-list-item {
    width: 100%;
    padding: 12px 22px 12px 16px;

  }
}

.seleccion-de-oficina__listado-item {
  margin-bottom: 16px;
}

.seleccion-de-oficina__listado-control {
  /* Box Model */
  padding: 0;
  width: 100%;

  /* Positioning */
  position: relative; //create positioning context

  /* Other */
  border: none;
  outline: none;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.05);

  &::after {
    content: "";

    /* Box Model */
    display: block;
    height: 12px;
    width: 6px;

    /* Positioning */
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);

    /* Other */
    background-size: contain;
    background-repeat: no-repeat;
    $chevronUrl: $image-path + '/chevron.svg';
    background-image: url($chevronUrl);

  }
}