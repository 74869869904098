.maquinero-card {
  /* Flex box */
  display: flex;
  flex-direction: column;
  //justify-content: center;
  justify-content: flex-start; // Pedido por Nico 2021-03-17
  align-items: center;

  /* Box Model */
  height: 280px; //**
  width: 160px;
  padding: 16px;
  padding-bottom: 0px;

  /* Other */
  background-color: white;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  overflow: hidden;

}

.maquinero-card__avatar {
  /* Positioning */
  position: relative;

  /* Box Model */
  margin-bottom: 16px;
}

.maquinero-card__avatar__notification-icon {
  /* Positioning */
  position: absolute;
  bottom: 0;
  right: 4px;
}

.maquinero-card__avatar__image {
  /* Box Model */
  width: 104px;
  height: 104px;

  /* Flexbox */
  display: flex;
  justify-content: center;
  align-items: center;

  /* Other */
  background-clip: content-box;
  background-position: center;
  background-size: cover;
  background-color: #6662A4;
  border: none;
  border-radius: 50%;

}

.maquinero-card__avatar__text {
  position: relative;
  top: -1px;
  left: 1px;
  font-size: 34px;
  line-height: 36px;
  font-weight: 700;
  color: $color--inverted;
}


.maquinero-card__nombre {
  /* Box Model */
  margin: 0;
  height: 30px;

  /* Typography */
  font-size: 12px;
  font-weight: 900;
  line-height: 15px;
  text-align: center;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-inline-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

}

.maquinero-card__aptitudes {
  /* Box Model */
  //margin: 8px 0 12px;
  margin-top: 8px;

  /* Typography */
  color: #666666;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  height: 33px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-inline-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.maquinero-card__control {
  @extend %button--no-background;
  &.button--stroke {
    @extend %button--stroke;
    /* Box Model */
    padding: 8px;
    width: 100%;

    /* Typography */
    font-size: 12px;
    line-height: 21px;

    /* Other */
    &:hover {
      background-color: $background-color--attention;
      color: $color--inverted;
    }
  }
}