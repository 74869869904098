/* Variables */
$status-width: 100%;
$status-height--mobile:  48px;
$status-height--desktop: 68px;
$nav-height--mobile:    48px;
$nav-height--desktop:   100%;
$nav-width--mobile:     100%;
$nav-width--desktop:    82px;
//$titulo-back-height--desktop: 68px; // Este era el diseño de FIGMA de Shiny
$titulo-back-height--desktop: 70px; // Esto pidió Nico en Febrero de 2021. Poner el mismo alto que los COVER de las pantallas principales (en DESKTOP es 256px)
$titulo-back-height--mobile: 56px;

.main-view {
    background-color: $background-color--base;
}

.status-bar {
    /* Variables */
    $height: $status-height--mobile;
    $width:  $status-width;

    /* Positioning */
    position: relative;
    z-index: 10;

    /* Box Model */
    height: $height;
    width:   $width;

    .desktop & {
        /* Variables */
        $height: $status-height--desktop;

        /**
        * Positioning
        * -----------
        * We render the status-bar atop the fixed primary-navigation in
        * desktop viewports.
        *
        */
        z-index: 20;

        /* Box Model */
        height: $height;
    }

    .button--user-avatar__notification-icon {
        visibility: hidden;
    }
}

.primary-navigation {
    /* Variables */
    $height: $nav-height--mobile;
    $width:   $nav-width--mobile;

    /* Positioning */
    position: relative;
    z-index: 10;

    /* Box Model */
    height: $height;
    width:   $width;

    .mobile & {
        position: fixed;
        bottom: 0px;
    }

    .button--user-avatar__notification-icon {
        visibility: hidden;
    }
}

.scrollable-content {
    /* Positioning */
    position: relative;
    z-index: 1;

    /* Box Model */
    height: 100%;

    /* Other */
    overflow-y: auto;

    .mobile & {
        /**
        * Size
        * ----
        * We define the *height* of .scrollable-content based on the presence of
        * the status and navigation bars. These are indicated by adding the
        * classes of:
        *
        * .layout--status-visible     : Status bar is present
        * .layout--navigation-visible : Navigation bar is present
        *
        */

        /* Variables */
        $status-bar--height:  $status-height--mobile;
        $navigation-bar--height: $nav-height--mobile;

        &.layout--status-visible {
            /* Box Model */
            height: calc( 100vh - #{$status-bar--height} );
        }
        &.layout--navigation-visible {
            /* Box Model */
            height: calc( 100vh - #{$navigation-bar--height} );
        }
        &.layout--status-visible.layout--navigation-visible {
            /* Box Model */
            height: calc( 100vh - #{$status-bar--height} - #{$navigation-bar--height} );
        }

    }

    .desktop & {
        /* Variables */
        $status-bar--height:  $status-height--desktop;
        $navigation-bar--width:  $nav-width--desktop;

        /**
        * Positioning
        * -----------
        * We push .scrollable-content from the left an amount equal to the
        * .primary-navigation's width on desktop viewports.
        *
        * [1] : We make sure the width of the .scrollable-content is reduced
        *       by the amount it's being pushed from the left by.
        */
        left: $navigation-bar--width;

        /**
        * Width
        * -----
        * The navigation bar is used as a vertical strip on landscape
        * viewports, we'll modify the *width* calculation accordingly.
        *
        * Furthermore, the status bar is meant to be scrollable so
        */

        // Armamos el arreglo de grilla sugerido por Shiny Octopus (max-width como el de 1280px)
        //max-width: calc(1280px - #{$navigation-bar--width}); Cambio a pedido de Nico 2021-03-17
        @media (min-width: 1281px) {
            margin: auto;
            left: calc(#{$navigation-bar--width} / 2);
        }

        /* Box Model */
        box-sizing: border-box;
        height: auto;
        min-height: calc( 100vh - #{$status-bar--height} );
        width: calc( 100% - #{$navigation-bar--width}); /* [1] */
    }
}