.ayuda-page {
  padding: 0 16px 100px 16px;


  .desktop & {
    padding: 0 24px 100px 24px;
  }

  .selector__wrapper {
    width: 100%;
    margin-bottom: 20px;
    padding: 40px 16px;

    &.is-unfolded {
      padding-bottom: 29px;
    }
    background-color: $background-color--secondary;
  }

  .selector {
    background: none;
    height: max-content;
    padding: 16px;
    box-shadow: none;
  }

  .selector__titulo {
    @extend %font-style--title;

    font-size: 16px;
    font-weight: 400;
    line-height: 20px;

    color: $color--primary;
  }

  .selector-list {
    box-shadow: none;
    background: none;
  }

  .selector-list-header {
    height: auto;
    flex-direction: column;
  }

  .selector-list__titulo {
    color: $color--secondary;
    font-size: 14px;
    line-height: 21px;
  }

  .ayuda-faq-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .lista-de-sedes {
    margin-top: 14px;
    line-height: 24px;
  }

}