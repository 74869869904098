.my-week {
    .experiences-page & {
        .event-list {
            /* Flexbox */
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;

            .desktop & {
                justify-content: flex-start;
            }


        }
        .event-item {
            /* Box Model */
            width: 360px;
            .desktop & {
                margin-right: 14px;
            }

        }
    }
}

.experiences-showcase {
    .experiences-page & {
        /* Other */
        background-image: none;
    }
}