/* Variables */
$main-spacing:       16px;

.comunidad-list {
    /* Flexbox */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .desktop & {
        justify-content: flex-start;
    }


    /* Box Model */
    margin:  0;
    padding: 0;

    /* Other */
    list-style-type: none;

    .maquinero-card {
        margin-bottom: 16px;
    }

}