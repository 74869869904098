.button,
%button {
    &--no-background {
        /* Other */
        background-color: transparent;
        border: none;
    }
    &--icon-and-text {
        &--row {
           /* Flexbox */
           display: flex;
           flex-direction: row;
           align-items: center;
        }
        &--column {
            /* Flexbox */
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
    &--icon-only {
        /* Box Model */
        margin: 0;
        padding: 0;
    }
    &--stroke {
        /* Box Model */
        padding: 16px;
        width: auto;

        /* Typography */
        color: $color--attention;
        font-size: 12px;
        font-weight: 700;
        line-height: 18px;
        text-align: center;
        text-transform: uppercase;

        /* Other */
        border-radius: 2px;
        box-shadow: 0 0 0 1px $color--attention;
    }
    &--primary {
        padding: 14px;
        background-color: $background-color--attention;
        border: none;

        /* Typography */
        @extend %font-style--attention;
        color: $color--inverted;

    }
}