.paquete-de-creditos {
  /* Box Model */
  height: 144px;
  width: 100%;
  padding: 16px;

  /* Other */
  background-color: white;
  border: none;
  border-radius: 2px;
  box-shadow: inset 0 0 0 1px #919eab;
  color: $background-color--tertiary;
  outline: none;

  /* Flex box */
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .desktop & {
    /* Box Model */
    height: 328px;
    width: 264px;
    padding: 20px;

    /* Flex box */
    flex-direction: column;
    align-items: center;
  }

  &.is-checked {
    /* Other */
    box-shadow: inset 0 0 0 1px $background-color--attention, 0 4px 8px 0 #dfe3e8;
  }

  &:focus {
    outline: none;
  }

}

.paquete-de-creditos-textos {
  /* Box Model */
  //height: 100%;
  //padding-left: 16px;

  /* Flex box */
  //flex: 1;
/*
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
*/

  /* Typography */
  text-align: left;

  .desktop & {
    text-align: center;
  }

}

.paquete-de-creditos-textos__titulo {
  /* Box Model */
  margin: 0;
  padding: 0;

  margin-bottom: 2px;

  /* Typography */
  font-size: 20px;
  font-weight: 400;
  line-height: 33px;
  color: $color--attention;

  .desktop & {
    margin-top: 23px;
    margin-bottom: 10px;
  }
}

.paquete-de-creditos-textos__creditos {
  /* Box Model */
  margin: 0;
  padding: 0;

  /* Typography */
  @extend %font-style--title;

  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  color: $color--primary;

  .mobile & {
    display: inline;
    margin-right: 10px;
  }

  .desktop & {
    font-size: 26px;
    line-height: 31px;
  }
}

.paquete-de-creditos-textos__valor {
  /* Box Model */
  margin: 0;

  /* Typography */
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 12px;

  .desktop & {
    margin-top: 15px;
  }
}

.paquete-de-creditos-textos__descripcion {
  /* Box Model */
  margin: 0;

  /* Typography */
  font-size: 14px;
  line-height: 20px;
}

.paquete-de-creditos-icon {
  /* Flex box */
  display: flex;
  justify-content: center;
  align-items: center;

  .desktop & {
    display: none;
  }


  /* Other */
  i[class^="icon"] {
    /* Box Model */
    height: 24px;
    width: 24px;

    /* Flex box */
    display: flex;
    justify-content: center;
    align-items: center;

    /* Typography */

    /* Other */
    background-color: #f4f6f8;
    border-radius: 50%;
    box-shadow: inset 0 0 0 1px #919eab;

    &::before {
      /* Box Model */
      display: none;

      /* Typography */
      font-size: 14px;

      .is-checked & {
        /* Box Model */
        display: block;

        /* Typography */
        color: white;
      }
    }

    .is-checked & {
      /* Other */
      background-color: $background-color--attention;
      box-shadow: none;
    }
  }
}

.paquete-de-creditos-control {
  height: 48px;
  width: 100%;
  border: 1px solid $color--attention;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: $background-color--base;
  color: $color--attention;

  @extend %font-style--attention;

  .is-checked & {
    background-color: $background-color--attention;
    color: $color--inverted;
    }

  .mobile & {
    display: none;
  }

  &:focus {
    outline: none;
  }
}